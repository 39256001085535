import "./App.css";
import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./Pages/Landing";
import Register from "./Pages/Register";
import Verification from "./Pages/Verification";
import Login from "./Pages/Login";
import TFA from "./Pages/TFA";
import FP from "./Pages/FP";
import Phising from "./Pages/Phising";
import Trade from "./Pages/Trade";
import Changepassword from "./Pages/Changepassword";
import EnableTFA from "./Pages/EnableTFA";
import Dashboard from "./Pages/Dashboard";
import Staking from "./Pages/Staking";
import Swap from "./Pages/Swap";
import Security from "./Pages/Security";
import Anti_phishing from "./Pages/Anti_phishing";
import Security_change from "./Pages/Security_change";
import Security_2fa from "./Pages/Security_2fa";
import Kyc from "./Pages/Kyc";
import Buycrypto from "./Pages/Buycrypto";
import Assets from "./Pages/Assets";
import DepositHistory from "./Pages/DepositHistory";
import Quick_buy from "./Pages/Quick_buy";
import Deposit from "./Pages/Deposit";

import Refferal from "./Pages/Refferal";

import WithdrawTable from "./Pages/WithdrawTable";
import TradeTable from "./Pages/TradeTable";
import LoginTable from "./Pages/LoginTable";
import ReferralTable from "./Pages/ReferralTable";
import StakingTable from "./Pages/StakingTable";
import SwapTable from "./Pages/SwapTable";
import OrderHisTable from "./Pages/OrderHisTable";
import CancelOrderTable from "./Pages/CancelOrderTable";
import StopOrderTable from "./Pages/StopOrderTable";
import Rewards from "./Pages/Rewards";
import Market from "./Pages/Market";
import Settings from "./Pages/Settings";
import Withdrawal from "./Pages/Withdrawal";
import Support from "./Pages/Support";
import FiatDeposit from "./Pages/FiatDeposit";
import AdminrewardTable from "./Pages/adminreward.Table";
// KYC
import KycProcess from "./Pages/kycProcess";
import Kyc_Information from "./Pages/Kyc_Information1";
import Kyc_Information2 from "./Pages/Kyc_information2";
import Kyc_Information3 from "./Pages/Kyc_information3";
import Kyc_Information4 from "./Pages/Kyc_information4";
import Kyc_Information5 from "./Pages/Kyc_information5";
import Kyc_Information6 from "./Pages/Kyc_information6";

import { removeAuthToken } from "../src/core/lib/localStorage";
// KYP
import Kyp from "./Pages/kyp";
import KypProcess from "./Pages/kypProcess";
import Kyp_Information1 from "./Pages/Kyp_information1";
import Kyp_Information2 from "./Pages/Kyp_information2";
import Kyp_Information3 from "./Pages/Kyp_information3";
import Kyp_Information4 from "./Pages/Kyp_information4";
import Kyp_Information5 from "./Pages/Kyp_information5";
import Kyp_Information6 from "./Pages/Kyp_information6";
import { useEffect } from "react";
import i18n from "./translate/i18n";
// import Test from  "./Pages/test"
import { useTranslation } from "react-i18next";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";

function App() {
  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  useEffect(() => {
    document.documentElement.lang = i18n.language; // Update lang attribute
  }, [i18n.language]);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/register" element={<Register />} />
          <Route path="/trade/:pair" element={<Trade />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/tfa" element={<TFA />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/forgotpassword" element={<FP />} />

          <Route
            path="/antiphishing"
            element={
              <RequireAuth>
                <Phising />
              </RequireAuth>
            }
          />
          <Route
            path="/changepassword"
            element={
              <RequireAuth>
                <Changepassword />
              </RequireAuth>
            }
          />
          <Route
            path="/enabletfa"
            element={
              <RequireAuth>
                {" "}
                <EnableTFA />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/staking" element={<Staking />} />
          <Route
            path="/depositHistory"
            element={
              <RequireAuth>
                <DepositHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/refferal"
            element={
              <RequireAuth>
                <Refferal />
              </RequireAuth>
            }
          />
          <Route
            path="/swap"
            element={  <Swap />
            }
          />
          {/* <Route
            path="/test"
            element={
              <RequireAuth>
                <Test />
              </RequireAuth>
            }
          /> */}

          <Route
            path="/security"
            element={
              <RequireAuth>
                <Security />
              </RequireAuth>
            }
          />
          <Route
            path="/anti-phishing"
            element={
              <RequireAuth>
                <Anti_phishing />
              </RequireAuth>
            }
          />
          <Route
            path="/security_change"
            element={
              <RequireAuth>
                <Security_change />
              </RequireAuth>
            }
          />
          <Route
            path="/security_2fa"
            element={
              <RequireAuth>
                <Security_2fa />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc"
            element={
              <RequireAuth>
                <Kyc />
              </RequireAuth>
            }
          />
          <Route
            path="/kyp"
            element={
              <RequireAuth>
                <Kyp />
              </RequireAuth>
            }
          />

          <Route
            path="/buycrypto"
            element={<Buycrypto /> }
          />
          <Route
            path="/assets"
            element={
              <RequireAuth>
                <Assets />
              </RequireAuth>
            }
          />
          <Route
            path="/withdrawHistory"
            element={
              <RequireAuth>
                <WithdrawTable />
              </RequireAuth>
            }
          />
          <Route
            path="/tradeHistory"
            element={
              <RequireAuth>
                <TradeTable />
              </RequireAuth>
            }
          />
          <Route
            path="/loginHistory"
            element={
              <RequireAuth>
                <LoginTable />
              </RequireAuth>
            }
          />
          <Route
            path="/referralHistory"
            element={
              <RequireAuth>
                <ReferralTable />
              </RequireAuth>
            }
          />
          <Route
            path="/adminrewardTable"
            element={
              <RequireAuth>
                <AdminrewardTable />
              </RequireAuth>
            }
          />

          <Route
            path="/stakingHistory"
            element={
              <RequireAuth>
                <StakingTable />
              </RequireAuth>
            }
          />
          <Route
            path="/swapHistory"
            element={
              <RequireAuth>
                <SwapTable />
              </RequireAuth>
            }
          />
          <Route
            path="/openorderHistory"
            element={
              <RequireAuth>
                <OrderHisTable />
              </RequireAuth>
            }
          />
          <Route
            path="/cancelorderHistory"
            element={
              <RequireAuth>
                <CancelOrderTable />
              </RequireAuth>
            }
          />
          <Route
            path="/stoporderHistory"
            element={
              <RequireAuth>
                <StopOrderTable />
              </RequireAuth>
            }
          />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/market" element={<Market />} />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                <Withdrawal />
              </RequireAuth>
            }
          />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <Deposit />
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            }
          />
          <Route
            path="/fiat_deposit"
            element={
              <RequireAuth>
                <FiatDeposit />
              </RequireAuth>
            }
          />
          <Route path="/quick_buy" element={<Quick_buy />} />
          <Route
            path="/KycInformation1"
            element={
              <RequireAuth>
                <Kyc_Information />
              </RequireAuth>
            }
          />
          <Route
            path="/KycInformation2"
            element={
              <RequireAuth>
                <Kyc_Information2 />
              </RequireAuth>
            }
          />
          <Route
            path="/KycInformation3"
            element={
              <RequireAuth>
                <Kyc_Information3 />
              </RequireAuth>
            }
          />
          <Route
            path="/KycInformation4"
            element={
              <RequireAuth>
                <Kyc_Information4 />
              </RequireAuth>
            }
          />
          <Route
            path="/KycInformation5"
            element={
              <RequireAuth>
                <Kyc_Information5 />
              </RequireAuth>
            }
          />
          <Route
            path="/KycInformation6"
            element={
              <RequireAuth>
                <Kyc_Information6 />
              </RequireAuth>
            }
          />
          {/* KYC */}
          {/* <Route path="/KycInformation1" element={<Kyc_Information />} />
          <Route path="/KycInformation2" element={<Kyc_Information2 />} />
          <Route path="/KycInformation3" element={<Kyc_Information3 />} />
          <Route path="/KycInformation4" element={<Kyc_Information4 />} />
          <Route path="/KycInformation5" element={<Kyc_Information5 />} />
          <Route path="/KycInformation6" element={<Kyc_Information6 />} /> */}
          {/*  */}
          {/* KYP */}
          <Route
            path="/kycProcess"
            element={
              <RequireAuth>
                <KycProcess />
              </RequireAuth>
            }
          />
          <Route
            path="/KypProcess"
            element={
              <RequireAuth>
                <KypProcess />
              </RequireAuth>
            }
          />

          <Route
            path="/KypInformation1"
            element={
              <RequireAuth>
                <Kyp_Information1 />
              </RequireAuth>
            }
          />
          <Route
            path="/KypInformation2"
            element={
              <RequireAuth>
                <Kyp_Information2 />
              </RequireAuth>
            }
          />
          <Route
            path="/KypInformation3"
            element={
              <RequireAuth>
                <Kyp_Information3 />
              </RequireAuth>
            }
          />
          <Route
            path="/KypInformation4"
            element={
              <RequireAuth>
                {" "}
                <Kyp_Information4 />
              </RequireAuth>
            }
          />
          <Route
            path="/KypInformation5"
            element={
              <RequireAuth>
                <Kyp_Information5 />
              </RequireAuth>
            }
          />
          <Route
            path="/KypInformation6"
            element={
              <RequireAuth>
                <Kyp_Information6 />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
