import React, { useEffect, useRef, useMemo } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";
import useState from "react-usestateref";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");

  const [selectBtn, setSelectBtn] = useState("yes");
  const [selectPerson, setSelectPerson] = useState("yes");
  const [selectAccess, setSelectAccess] = useState("yes");
  const [selectAssociate, setSelectAssociate] = useState("yes");
  const [selectCompCheck, setSelectCompCheck] = useState(false);
  const [selectProof, setSelectProof] = useState(false);

  const [isPhysical, setIsPhysical] = useState("yes");
  const [isLegal, setIsLegal] = useState("yes");
  const [isControlCompany, setIsControlCompany] = useState("yes");
  const [isPerson, setIsPerson] = useState("yes");
  const [isAccountHolder, setIsAccountHolder] = useState("yes");
  const [isPublic, setIsPublic] = useState("yes");
  const [selectLicense, setSelectLicense] = useState(false);
  const [credit, setCredit] = useState(false);
  const [isShowStatement, setIsShowStament] = useState(false);

  const [beneficialOwner, setBeneficialOwner] = useState(false);
  const [parentCompany, setParentCompany] = useState(false);
  const [selectParCheck, setSelectParCheck] = useState(false);
  const [selectParComp, setSelectParComp] = useState(false);
  const [selectParProof, setSelectParProof] = useState(false);
  const [isProof, setIsProof] = useState(false);
  const [proofchoosingName, setproofchoosingName] = useState();

  const options = useMemo(() => countryList().getData(), []);

  const { t } = useTranslation();

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  /*---PERSON OPENING THE ACCOUNT ACCORDIAN [YES OR NO] */

  // person maintain yes or no
  const yesBtnClick = () => {
    setSelectBtn("yes");
  };
  const noBtnClick = () => {
    setSelectBtn("no");
  };

  // access to the account
  const handleYesAccess = () => {
    setSelectPerson("yes");
    formValue.relativeStatus = "yes";
  };
  const handleNoAccess = () => {
    setSelectPerson("no");
  };

  // account holder
  const accountYes = () => {
    setSelectAccess("yes");
    formValue.politicalExposes = "yes";
  };
  const accountNo = () => {
    setSelectAccess("no");
    formValue.politicalExposes = "no";
    formValue.politicalFirstName = "";
    formValue.politicalSurName = "";
  };

  // relative or associate
  const handleYesAssociate = () => {
    setSelectAssociate("yes");
    formValue.relativeStatus = "yes";
  };
  const handleNoAssociate = () => {
    setSelectAssociate("no");
    formValue.relativeStatus = "no";
    formValue.relativeFirstName = "";
    formValue.relativeSurName = "";
  };

  const handleCheckComp = () => {
    setSelectCompCheck((prevCheck) => !prevCheck);
  };
  const handleCheckProof = () => {
    setSelectProof((prevCheck) => !prevCheck);
    console.log(selectProof, ";ookidsofiasdop");
  };

  /*---BENEFICIAL OWNERSHIP ACCORDIAN [YES OR NO] */
  const handleYesPhy = () => {
    setIsPhysical("yes");
  };
  const handleNoPhy = () => {
    setIsPhysical("no");
  };

  const handleYesLegal = () => {
    setIsLegal("yes");
  };
  const handleNoLegal = () => {
    setIsLegal("no");
  };

  const handleYesControl = () => {
    setIsControlCompany("yes");
  };
  const handleNoControl = () => {
    setIsControlCompany("no");
  };

  const handleYesPerson = () => {
    setIsPerson("yes");
  };
  const handleNoPerson = () => {
    setIsPerson("no");
  };

  const handleYesHolder = () => {
    setIsAccountHolder("yes");
  };
  const handleNoHolder = () => {
    setIsAccountHolder("no");
  };

  const handleYesPublic = () => {
    setIsPublic("yes");
  };
  const handleNoPublic = () => {
    setIsPublic("no");
  };

  const handleLicense = () => {
    setSelectLicense(!selectLicense);
  };

  const handleCredit = () => {
    setCredit(!credit);
  };

  const handleShowStatement = () => {
    setIsShowStament(!isShowStatement);
    setproofchoosingName(value);
    setSelectProof(false);
    parentaladdressProofref("");
    validaddressProof(0);
  };

  // handleParentClick and handleBenefitclick => for switching tabs like nav-tabs

  // These 4 funcs regarding Accordian 2(Beneficial Ownership => Add Parent Company => Please registration your business registration document)
  const handleParCheck = () => {
    setSelectParCheck((prevCheck) => !prevCheck);
  };

  const handleParComp = () => {
    setSelectParComp((prevComp) => !prevComp);
  };

  const handleParProof = () => {
    setSelectParProof((prevProof) => !prevProof);
  };

  const handleShowProof = () => {
    setIsProof(!isProof);
  };
  // CODE AUTHOR JOTHI =====================

  const documentOptions = [
    { value: "Passport", label: t("passport") },
    { value: "Driver's license", label: t("driver-license") },
    { value: "ID Card", label: t("id-card") },
  ];

  const addressProofOptions = [
    { value: "Bank Statement", label: t("bank-statement") },
    { value: "Credit Card Statement", label: t("credit-card-statement") },
    { value: "Utility Bill", label: t("utility-bill") },
    { value: "Salary Slip", label: t("salary-slip") },
    { value: "Insurance Statement", label: t("insurance-statement") },
    { value: "Tax Document", label: t("tax-document") },
    { value: "Residence Certificate", label: t("residence-certificate") },
  ];

  const registrationOptions = [
    { value: "Corporation", label: t("corporation") },
    {
      value: "ChildLimited Liability Corporation (LLC)",
      label: t("childlimited-liability-corporation"),
    },
    { value: "Non-Profit", label: t("non-profit") },
    { value: "Partnership", label: t("partnership") },
    { value: "Sole Proprietorship", label: t("sole-proprietorship") },
    { value: "Trust", label: t("trust") },
    {
      value: "Unincorporated Association",
      label: t("unincorporated-association"),
    },
  ];

  const documentOptions2 = [
    { value: "Utility Bill", label: t("utility-bill") },
    { value: "Bank Statement ", label: t("bank-statement") },
    { value: "Credit Card Statement", label: t("credit-card-statement") },
    {
      value: "Proof of Residency Certificate",
      label: t("proof-of-residency-certificate"),
    },
  ];

  const initialFormValue = {
    firstName: "",
    surName: "",
    Dob: "",
    Address: "",
    unit: "",
    city: "",
    postalCode: "",
    country: "",
    Nationality: "",
    ownerShip: "",
    companyRole: "",
    maintainStatuis: "",
    accountAccess: "",
    politicalExposes: "yes",
    politicalFirstName: "",
    politicalSurName: "",
    relativeStatus: "yes",
    relativeFirstName: "",
    relativeSurName: "",
    identityDocument: "",
    addressProof: "",
    companyphysicalstatus: "",
    legalexemptstatus: "",
    beneficilaOwner: [],
    parentsCompany: [],
    // beneficialfirstName: "",
    // beneficialsurName:"",
    // beneficialDob:"",
    // beneficialAddress:"",
    // beneficialunit:"",
    // beneficialcity:"",
    // beneficialpostalCode:"",
    // beneficialcountry:"",
    // beneficialNationality:"",
    // beneficialownerShip:"",
    // beneficialcompanyRole:"",
    // beneficialmaintainStatuis:"",
    // beneficialaccountAccess:"",
    // beneficialpoliticalExposes:"",
    // beneficialpoliticalFirstName: "",
    // beneficialpoliticalSurName:"",
    // beneficialrelativeStatus:"",
    // beneficialrelativeFirstName: "",
    // beneficialrelativeSurName:"",
    // beneficialidentityDocument:"",
    // beneficialaddressProof:"",
    // parentalregistrationtype: "",
    // parentalregistrationnumber: "",
    // parentalDateofformation: "",
    // parentalCountry: "",
    // parentalownershipshare:"",
    // parentalcorporateFormationproof:"",
    // parentalcompanyproof:"",
    // parentaladdressProof:"",
    // parentaladdressProofName:"",
    organizalChart: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const {
    firstName,
    surName,
    Dob,
    Address,
    unit,
    city,
    postalCode,
    country,
    Nationality,
    ownerShip,
    companyRole,
    maintainStatuis,
    accountAccess,
    politicalExposes,
    politicalFirstName,
    politicalSurName,
    relativeStatus,
    relativeFirstName,
    relativeSurName,
    identityDocument,
    addressProof,
    companyphysicalstatus,
    legalexemptstatus,
    beneficilaOwner,
    parentsCompany,
    // beneficialfirstName,
    // beneficialsurName,
    // beneficialDob,
    // beneficialAddress,
    // beneficialunit,
    // beneficialcity,
    // beneficialpostalCode,
    // beneficialcountry,
    // beneficialNationality,
    // beneficialownerShip,
    // beneficialcompanyRole,
    // beneficialmaintainStatuis,
    // beneficialaccountAccess,
    // beneficialpoliticalExposes,
    // beneficialpoliticalFirstName,
    // beneficialpoliticalSurName,
    // beneficialrelativeStatus,
    // beneficialrelativeFirstName,
    // beneficialrelativeSurName,
    // beneficialidentityDocument,
    // beneficialaddressProof,
    // parentalregistrationtype,
    // parentalregistrationnumber,
    // parentalDateofformation,
    // parentalCountry,
    // parentalownershipshare,
    // parentalcorporateFormationproof,
    // parentalcompanyproof,
    // parentaladdressProof,
    // parentaladdressProofName,
    organizalChart,
  } = formValue;

  const [beneficilaOwners, setbeneficilaOwner] = useState([]);

  // const handleParentClick = () => {
  //   setParentCompany(true);
  //   setBeneficialOwner(false);
  //   console.log("parent");
  // };

  // const handleBenefitClick = () => {
  //   setBeneficialOwner(true);
  //   setParentCompany(false);
  //   console.log("benefit");

  //   setbeneficilaOwner([
  //     ...beneficilaOwners,
  //     {
  //   beneficialfirstName: "",
  //   beneficialsurName:"",
  //   beneficialDob:"",
  //   beneficialAddress:"",
  //   beneficialunit:"",
  //   beneficialcity:"",
  //   beneficialpostalCode:"",
  //   beneficialcountry:"",
  //   beneficialNationality:"",
  //   beneficialownerShip:"",
  //   beneficialcompanyRole:"",
  //   beneficialmaintainStatuis:"",
  //   beneficialaccountAccess:"",
  //   beneficialpoliticalExposes:"",
  //   beneficialpoliticalFirstName: "",
  //   beneficialpoliticalSurName:"",
  //   beneficialrelativeStatus:"",
  //   beneficialrelativeFirstName: "",
  //   beneficialrelativeSurName:"",
  //   beneficialidentityDocument:"",
  //   beneficialaddressProof:"",
  //      },
  //   ]);

  // };

  // const handleOwnersDelete = (index) => {
  //   const updatedOwners = beneficilaOwners.filter((_, i) => i !== index);
  //   setbeneficilaOwner(updatedOwners);
  // };

  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);
  const [idproof, setidproof, idproofref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofup, setaddressProof, addressProofref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const [beneficialvalididproof, setbeneficialvalididproof] = useState(0);
  const [beneficialidproofLoad, setbeneficialidproofLoad] = useState(false);
  const [beneficialidproof, setbeneficialidproof, beneficialidroofref] =
    useState("");
  const [
    beneficialidproofname,
    setbeneficialidproofname,
    beneficialidproofnameref,
  ] = useState("");
  const [beneficialvalidaddressProof, setbeneficialvalidaddressProof] =
    useState(0);
  const [
    beneficialaddressProofup,
    setbeneficialaddressProof,
    beneficialaddressProofref,
  ] = useState("");
  const [
    beneficialaddressproofname,
    setbeneficialaddressproofname,
    beneficialaddressproofnameref,
  ] = useState("");
  const [beneficialaddressProofLoad, setbeneficialaddressProofLoad] =
    useState(false);

  const [validcorporateFormationproof, setvalidcorporateFormationproof] =
    useState(0);
  const [corporateFormationproofLoad, setcorporateFormationproofLoad] =
    useState(false);
  const [
    corporateFormationproof,
    setcorporateFormationproof,
    corporateFormationproofref,
  ] = useState("");
  const [
    corporateFormationproofname,
    setcorporateFormationproofname,
    corporateFormationproofnameref,
  ] = useState("");
  const [validcompanyproof, setvalidcompanyproof] = useState(0);
  const [companyproofLoad, setcompanyproofLoad] = useState(false);
  const [companyproof, setcompanyproof, companyproofref] = useState("");
  const [companyproofname, setcompanyproofname, companyproofnameref] =
    useState("");
  const [parentalvalidaddressProof, setparentalvalidaddressProof] = useState(0);
  const [
    parentaladdressProofup,
    setparentaladdressProof,
    parentaladdressProofref,
  ] = useState("");
  const [
    parentaladdressproofname,
    setparentaladdressproofname,
    parentaladdressproofnameref,
  ] = useState("");
  const [parentaladdressProofLoad, setparentaladdressProofLoad] =
    useState(false);

  const [organizevalididproof, setorganizevalididproof] = useState(0);
  const [organizeidproofLoad, setorganizeidproofLoad] = useState(false);
  const [organizeidproof, setorganizeidproof, organizeidroofref] = useState("");
  const [organizeidproofname, setorganizeidproofname, organizeidproofnameref] =
    useState("");

  const [firstNameErrMsg, setfirstNameErrMsg, firstNameErrMsgref] =
    useState("");
  const [firstNamevallidate, setfirstNamevallidate, firstNamevallidateref] =
    useState(false);
  const [surNameErrMsg, setsurNameErrMsg, surNameErrMsgref] = useState("");
  const [surNamevallidate, setsurNamevallidate, surNamevallidateref] =
    useState(false);
  const [dobErrMsg, setdobErrMsg, dobErrMsgref] = useState("");
  const [dobvallidate, setdobvallidate, dobvallidateref] = useState(false);
  const [addressErrMsg, setaddressErrMsg, addressErrMsgref] = useState("");
  const [addressvallidate, setaddressvallidate, addressvallidateref] =
    useState(false);
  const [cityErrMsg, setcityErrMsg, cityErrMsgref] = useState("");
  const [cityvallidate, setcityvallidate, cityvallidateref] = useState(false);
  const [countryErrMsg, setcountryErrMsg, countryErrMsgref] = useState("");
  const [countryvallidate, setcountryvallidate, countryvallidateref] =
    useState(false);
  const [postalCodeErrMsg, setpostalCodeErrMsg, postalCodeErrMsgref] =
    useState("");
  const [postalCodevallidate, setpostalCodevallidate, postalCodevallidateref] =
    useState(false);
  const [nationalityErrMsg, setnationalityErrMsg, nationalityErrMsgref] =
    useState("");
  const [
    nationalityvallidate,
    setnationalityvallidate,
    nationalityvallidateref,
  ] = useState(false);
  const [ownerShipErrMsg, setownerShipErrMsg, ownerShipErrMsgref] =
    useState("");
  const [ownerShipvallidate, setownerShipvallidate, ownerShipvallidateref] =
    useState(false);
  const [companyRoleErrMsg, setcompanyRoleErrMsg, companyRoleErrMsgref] =
    useState("");
  const [
    companyRolevallidate,
    setcompanyRolevallidate,
    companyRolevallidateref,
  ] = useState(false);
  const [
    politicalFirstNameErrMsg,
    setpoliticalFirstNameErrMsg,
    politicalFirstNameErrMsgref,
  ] = useState("");
  const [
    politicalFirstNamevallidate,
    setpoliticalFirstNamevallidate,
    politicalFirstNamevallidateref,
  ] = useState(false);
  const [
    politicalSurNameErrMsg,
    setpoliticalSurNameErrMsg,
    politicalSurNameErrMsgref,
  ] = useState("");
  const [
    politicalSurNamevallidate,
    setpoliticalSurNamevallidate,
    politicalSurNamevallidateref,
  ] = useState(false);
  const [
    relativeFirstNameErrMsg,
    setrelativeFirstNameErrMsg,
    relativeFirstNameErrMsgref,
  ] = useState("");
  const [
    relativeFirstNamevallidate,
    setrelativeFirstNamevallidate,
    relativeFirstNamevallidateref,
  ] = useState(false);
  const [
    relativeSurNameErrMsg,
    setrelativeSurNameErrMsg,
    relativeSurNameErrMsgref,
  ] = useState("");
  const [
    relativeSurNamevallidate,
    setrelativeSurNamevallidate,
    relativeSurNamevallidateref,
  ] = useState(false);
  const [
    identityDocumentErrMsg,
    setidentityDocumentErrMsg,
    identityDocumentErrMsgref,
  ] = useState("");
  const [
    identityDocumentvallidate,
    setidentityDocumentvallidate,
    identityDocumentvallidateref,
  ] = useState(false);
  const [addressProofErrMsg, setaddressProofErrMsg, addressProofErrMsgref] =
    useState("");
  const [
    addressProofvallidate,
    setaddressProofvallidate,
    addressProofvallidateref,
  ] = useState(false);

  // const [beneficialfirstNameErrMsg,setbeneficialfirstNameErrMsg,beneficialfirstNameErrMsgref]=useState(false);
  // const [beneficialfirstNamevallidate,setbeneficialfirstNamevallidate,beneficialfirstNamevallidateref]=useState("");
  // const [beneficialsurNameErrMsg,setbeneficialsurNameErrMsg,beneficialsurNameErrMsgref]=useState(false);
  // const [beneficialsurNamevallidate,setbeneficialsurNamevallidate,beneficialsurNamevallidateref]=useState("");
  // const [beneficialdobErrMsg,setbeneficialdobErrMsg,beneficialdobErrMsgref]=useState(false);
  // const [beneficialdobvallidate,setbeneficialdobvallidate,beneficialdobvallidateref]=useState("");
  // const [beneficialaddressErrMsg,setbeneficialaddressErrMsg,beneficialaddressErrMsgref]=useState(false);
  // const [beneficialaddressvallidate,setbeneficialaddressvallidate,beneficialaddressvallidateref]=useState("");
  // const [beneficialcityErrMsg,setbeneficialcityErrMsg,beneficialcityErrMsgref]=useState(false);
  // const [beneficialcityvallidate,setbeneficialcityvallidate,beneficialcityvallidateref]=useState("");
  // const [beneficialcountryErrMsg,setbeneficialcountryErrMsg,beneficialcountryErrMsgref]=useState(false);
  // const [beneficialcountryvallidate,setbeneficialcountryvallidate,beneficialcountryvallidateref]=useState("");
  // const [beneficialpostalCodeErrMsg,setbeneficialpostalCodeErrMsg,beneficialpostalCodeErrMsgref]=useState(false);
  // const [beneficialpostalCodevallidate,setbeneficialpostalCodevallidate,beneficialpostalCodevallidateref]=useState("");
  // const [beneficialnationalityErrMsg,setbeneficialnationalityErrMsg,beneficialnationalityErrMsgref]=useState(false);
  // const [beneficialnationalityvallidate,setbeneficialnationalityvallidate,beneficialnationalityvallidateref]=useState("");
  // const [beneficialownerShipErrMsg,setbeneficialownerShipErrMsg,beneficialownerShipErrMsgref]=useState(false);
  // const [beneficialownerShipvallidate,setbeneficialownerShipvallidate,beneficialownerShipvallidateref]=useState("");
  // const [beneficialcompanyRoleErrMsg,setbeneficialcompanyRoleErrMsg,beneficialcompanyRoleErrMsgref]=useState(false);
  // const [beneficialcompanyRolevallidate,setbeneficialcompanyRolevallidate,beneficialcompanyRolevallidateref]=useState("");
  // const [beneficialpoliticalFirstNameErrMsg,setbeneficialpoliticalFirstNameErrMsg,beneficialpoliticalFirstNameErrMsgref]=useState(false);
  // const [beneficialpoliticalFirstNamevallidate,setbeneficialpoliticalFirstNamevallidate,beneficialpoliticalFirstNamevallidateref]=useState("");
  // const [beneficialpoliticalSurNameErrMsg,setbeneficialpoliticalSurNameErrMsg,beneficialpoliticalSurNameErrMsgref]=useState(false);
  // const [beneficialpoliticalSurNamevallidate,setbeneficialpoliticalSurNamevallidate,beneficialpoliticalSurNamevallidateref]=useState("");
  // const [beneficialrelativeFirstNameErrMsg,setbeneficialrelativeFirstNameErrMsg,beneficialrelativeFirstNameErrMsgref]=useState(false);
  // const [beneficialrelativeFirstNamevallidate,setbeneficialrelativeFirstNamevallidate,beneficialrelativeFirstNamevallidateref]=useState("");
  // const [beneficialrelativeSurNameErrMsg,setbeneficialrelativeSurNameErrMsg,beneficialrelativeSurNameErrMsgref]=useState(false);
  // const [beneficialrelativeSurNamevallidate,setbeneficialrelativeSurNamevallidate,beneficialrelativeSurNamevallidateref]=useState("");
  // const [beneficialidentityDocumentErrMsg,setbeneficialidentityDocumentErrMsg,beneficialidentityDocumentErrMsgref]=useState(false);
  // const [beneficialidentityDocumentvallidate,setbeneficialidentityDocumentvallidate,beneficialidentityDocumentvallidateref]=useState("");
  // const [beneficialaddressProofErrMsg,setbeneficialaddressProofErrMsg,beneficialaddressProofErrMsgref]=useState(false);
  // const [beneficialaddressProofvallidate,setbeneficialaddressProofvallidate,beneficialaddressProofvallidateref]=useState("");

  // const [parentalregistrationtypevallidate,setparentalregistrationtypevallidate, parentalregistrationtypevallidateref,] = useState("");
  // const [parentalregistrationtypeErrMsg,setparentalregistrationtypeErrMsg,parentalregistrationtypeErrMsgref,] = useState("");
  // const [parentalregistrationnumberErrMsg, setparentalregistrationnumberErrMsg, parentalregistrationnumberErrMsgref] = useState("");
  // const [parentalregistrationnumbervallidate, setparentalregistrationnumbervallidate, parentalregistrationnumbervallidateref] =useState("");
  // const [parentalDateofformationvallidate, setparentalDateofformationvallidate, parentalDateofformationvallidateref] = useState("");
  // const [parentalDateofformationErrMsg, setparentalDateofformationErrMsg, parentalDateofformationErrMsgref] = useState("");
  // const [parentalCountryErrMsg, setparentalCountryErrMsg, parentalCountryErrMsgref] = useState("");
  // const [parentalCountryvallidate, setparentalCountryvallidate, parentalCountryvallidateref] = useState("");
  // const [parentalownershipshareErrMsg, setparentalownershipshareErrMsg, parentalownershipshareErrMsgref] = useState("");
  // const [parentalownershipsharevallidate, setparentalownershipsharevallidate, parentalownershipsharevallidateref] = useState("");
  // const [corporateFormErrMsg, setcorporateFormErrMsg, corporateFormErrMsgref] = useState("");
  // const [corporateFormvallidate, setcorporateFormvallidate, corporateFormvallidateref] = useState("");
  // const [companyFormErrMsg, setcompanyFormErrMsg, companyFormErrMsgref] = useState("");
  // const [companyFormvallidate, setcompanyFormvallidate, companyFormvallidateref] = useState("");
  // const [addressFormErrMsg, setaddressFormErrMsg, addressFormErrMsgref] = useState("");
  // const [addressFormvallidate, setaddressFormvallidate, addressFormvallidateref] = useState("");
  const [
    organizalChartErrMsg,
    setorganizalChartErrMsg,
    organizalChartErrMsgref,
  ] = useState("");
  const [
    organizalChartvallidate,
    setorganizalChartvallidate,
    organizalChartvallidateref,
  ] = useState("");

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    console.log(formValue, "]]]]");
  };

  const handleCountryChange = (selectedCountry) => {
    const correspondingNationality = options.find(
      (option) => option.value === selectedCountry.value
    );

    const updatedFormValue = {
      ...formValue,
      country: selectedCountry.value,
      Nationality: correspondingNationality
        ? correspondingNationality.value
        : "",
    };
    setFormValue(updatedFormValue);
  };

  const handleNationalityChange = (selectedNationality) => {
    const updatedFormValue = {
      ...formValue,
      Nationality: selectedNationality.value,
    };

    setFormValue(updatedFormValue);
  };

  const handleDocumentChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      identityDocument: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };

  const handleAddressProofChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      addressProof: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(t("file-does-not-support"));
      } else if (fileSize > 10000000) {
        toast.error(t("please-upload-file-smaller-than-1mb"));
      } else {
        type == "identityDocument"
          ? setidproofLoad(true)
          : setaddressProofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "identityDocument") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }
              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);
              setaddressProof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(t("please-try-again-later"));
          });
      }
    } catch (error) {
      toast.error(t("please-try-again-later"));
    }
  };

  const beneficialhandleDocumentChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      beneficialidentityDocument: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };

  // const beneficialhandleAddressProofChange = (selectedOption) => {
  //   const updatedFormValue = {
  //     ...formValue,
  //     beneficialaddressProof: selectedOption.value,
  //   };
  //   setFormValue(updatedFormValue);
  // };

  // const beneficialimageUpload = (type, val) => {
  //   try {
  //     const fileExtension = val.name.split(".").at(-1);
  //     const fileSize = val.size;
  //     const fileName = val.name;
  //     if (
  //       fileExtension != "png" &&
  //       fileExtension != "jpg" &&
  //       fileExtension != "jpeg"
  //     ) {
  //       toast.error(
  //         "File does not support. You must use .png or .jpg or .jpeg "
  //       );
  //     } else if (fileSize > 10000000) {
  //       toast.error("Please upload a file smaller than 1 MB");
  //     } else {
  //       type == "identityDocument"
  //         ? setbeneficialidproofLoad(true)
  //         : setbeneficialaddressProofLoad(true);
  //       const data = new FormData();
  //       data.append("file", val);
  //       data.append("upload_preset", env.upload_preset);
  //       data.append("cloud_name", env.cloud_name);
  //       fetch(
  //         "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
  //         { method: "post", body: data }
  //       )
  //         .then((resp) => resp.json())
  //         .then((data) => {
  //           if (type == "identityDocument") {
  //             setbeneficialidproofLoad(false);
  //             setbeneficialvalididproof(2);
  //             if (
  //               fileExtension == "pdf" ||
  //               fileExtension == "odt" ||
  //               fileExtension == "doc"
  //             ) {
  //               setbeneficialvalididproof(1);
  //             }
  //             setbeneficialidproof(data.secure_url);
  //             setbeneficialidproofname(val.name);
  //           }
  //           if (type == "addressProof") {
  //             setbeneficialaddressProofLoad(false);
  //             setbeneficialvalidaddressProof(2);
  //             if (
  //               fileExtension == "pdf" ||
  //               fileExtension == "odt" ||
  //               fileExtension == "doc"
  //             ) {
  //               setbeneficialvalidaddressProof(1);
  //             }
  //             setbeneficialaddressproofname(val.name);
  //             setbeneficialaddressProof(data.secure_url);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           toast.error("Please try again later");
  //         });
  //     }
  //   } catch (error) {
  //     console.log(error,"00-0-0-")
  //     toast.error("Please try again later");
  //   }
  // }

  // const handlebeneficialChange = (index, key, value) => {
  //   const updatedOwners = beneficilaOwners.map((owner, i) =>
  //     i === index ? { ...owner, [key]: value } : owner
  //   );
  //   setbeneficilaOwner(updatedOwners);
  // };

  // const handlebeneficialCountryChange = (selectedCountry) => {
  //   const correspondingNationality = options.find(
  //     (option) => option.value === selectedCountry.value
  //   );
  //   const updatedFormValue = {
  //     ...formValue,
  //     beneficialcountry: selectedCountry.value,
  //     beneficialNationality: correspondingNationality
  //       ? correspondingNationality.value
  //       : "",
  //   };
  //   setFormValue(updatedFormValue);
  // };

  // const handlebeneficialNationalityChange = (selectedNationality) => {
  //   const updatedFormValue = {
  //     ...formValue,
  //     beneficialNationality: selectedNationality.value,
  //   };

  //   setFormValue(updatedFormValue);
  // };

  // const handleregtypeChange = (selectOption) => {
  //   const updatedFormValue = {
  //     ...formValue,
  //     parentalregistrationtype: selectOption.value,
  //   };
  //   setFormValue(updatedFormValue);
  // };

  // const handleparentalCountryChange = (selectedCountry) => {
  //   const updatedFormValue = {
  //     ...formValue,
  //     parentalCountry: selectedCountry.value,
  //   };
  //   setFormValue(updatedFormValue);
  // };

  const organizeimageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(t("file-does-not-support"));
      } else if (fileSize > 10000000) {
        toast.error(t("please-upload-file-smaller-than-1mb"));
      } else {
        setorganizeidproofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "organizechart") {
              setorganizeidproofLoad(false);
              setorganizevalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setorganizevalididproof(1);
              }
              setorganizeidproof(data.secure_url);
              formValue.organizalChart = data.secure_url;
              setorganizeidproofname(val.name);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(t("please-try-again-later"));
          });
      }
    } catch (error) {
      toast.error(t("please-try-again-later"));
    }
  };

  const handleparentAddressProofChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      parentaladdressProof: selectedOption.value,
    };
    setFormValue(updatedFormValue);
  };

  // const parentalimageUpload = (type, val) => {
  //   try {
  //     const fileExtension = val.name.split(".").at(-1);
  //     const fileSize = val.size;
  //     const fileName = val.name;
  //     if (
  //       fileExtension != "png" &&
  //       fileExtension != "jpg" &&
  //       fileExtension != "jpeg"
  //     ) {
  //       toast.error(
  //         "File does not support. You must use .png or .jpg or .jpeg "
  //       );
  //     } else if (fileSize > 10000000) {
  //       toast.error("Please upload a file smaller than 1 MB");
  //     } else {
  //       type == "corporateFormation"
  //         ? setcorporateFormationproofLoad(true)
  //         : type == "companyShareRegistry"
  //         ? setcompanyproofLoad(true)
  //         : setparentaladdressProofLoad(true);
  //       const data = new FormData();
  //       data.append("file", val);
  //       data.append("upload_preset", env.upload_preset);
  //       data.append("cloud_name", env.cloud_name);
  //       fetch(
  //         "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
  //         { method: "post", body: data }
  //       )
  //         .then((resp) => resp.json())
  //         .then((data) => {
  //           if (type == "corporateFormation") {
  //             setcorporateFormationproofLoad(false);
  //             setvalidcorporateFormationproof(2);
  //             if (
  //               fileExtension == "pdf" ||
  //               fileExtension == "odt" ||
  //               fileExtension == "doc"
  //             ) {
  //               setvalidcorporateFormationproof(1);
  //             }
  //             setcorporateFormationproof(data.secure_url);
  //             setcorporateFormationproofname(val.name);
  //           }
  //           if (type == "companyShareRegistry") {
  //             setcompanyproofLoad(false);
  //             setvalidcompanyproof(2);
  //             if (
  //               fileExtension == "pdf" ||
  //               fileExtension == "odt" ||
  //               fileExtension == "doc"
  //             ) {
  //               setvalidcompanyproof(1);
  //             }
  //             setcompanyproofname(val.name);
  //             setcompanyproof(data.secure_url);
  //           }
  //           if (type == "addressProof") {
  //             setparentaladdressProofLoad(false);
  //             setparentalvalidaddressProof(2);
  //             if (
  //               fileExtension == "pdf" ||
  //               fileExtension == "odt" ||
  //               fileExtension == "doc"
  //             ) {
  //               setparentalvalidaddressProof(1);
  //             }
  //             setparentaladdressproofname(val.name);
  //             setparentaladdressProof(data.secure_url);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           toast.error("Please try again later");
  //         });
  //     }
  //   } catch (error) {
  //     toast.error("Please try again later");
  //   }
  // };

  const vallidation1 = () => {
    try {
      console.log(formValue, "=-=-=");
      if (formValue.firstName === "") {
        setfirstNameErrMsg(t("first-name-required"));
        setfirstNamevallidate(true);
      } else if (!/^[a-zA-Z]/.test(formValue.firstName)) {
        setfirstNameErrMsg(t("firstname-must-start-letter"));
        setfirstNamevallidate(true);
      } else if (
        formValue.firstName.length < 3 ||
        formValue.firstName.length > 25
      ) {
        setfirstNameErrMsg(t("firstname-must-3-25-char"));
        setfirstNamevallidate(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(formValue.firstName)) {
        setfirstNameErrMsg(t("fname-only-contain-letter-num-underscore"));
        setfirstNamevallidate(true);
      } else {
        setfirstNamevallidate(false);
        setfirstNameErrMsg("");
      }

      if (formValue.surName === "") {
        setsurNameErrMsg(t("surname-required"));
        setsurNamevallidate(true);
      } else if (!/^[a-zA-Z]/.test(formValue.surName)) {
        setsurNameErrMsg(t("surname-must-start-letter"));
        setsurNamevallidate(true);
      } else if (
        formValue.surName.length < 3 ||
        formValue.surName.length > 25
      ) {
        setsurNameErrMsg(t("surname-must-3-25-char"));
        setsurNamevallidate(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(formValue.surName)) {
        setsurNameErrMsg(t("surname-only-contain-letter-num-underscore"));
        setsurNamevallidate(true);
      } else {
        setsurNamevallidate(false);
        setsurNameErrMsg("");
      }

      if (formValue.Dob == "") {
        setdobvallidate(true);
        setdobErrMsg(t("date-of-birth-required"));
      } else {
        setdobvallidate(false);
        setdobErrMsg("");
      }

      if (formValue.Address == "") {
        setaddressvallidate(true);
        setaddressErrMsg(t("address-required"));
      } else {
        setaddressvallidate(false);
        setaddressErrMsg("");
      }

      if (formValue.city == "") {
        setcityvallidate(true);
        setcityErrMsg(t("city-required"));
      } else {
        setcityvallidate(false);
        setcityErrMsg("");
      }

      if (formValue.postalCode == "") {
        setpostalCodevallidate(true);
        setpostalCodeErrMsg(t("postal-code-required"));
      } else {
        setpostalCodevallidate(false);
        setpostalCodeErrMsg("");
      }

      if (formValue.country == "") {
        setcountryvallidate(true);
        setcountryErrMsg(t("country-required"));
      } else {
        setcountryvallidate(false);
        setcountryErrMsg("");
      }

      if (formValue.Nationality == "") {
        setnationalityvallidate(true);
        setnationalityErrMsg(t("nationality-required"));
      } else {
        setnationalityvallidate(false);
        setnationalityErrMsg("");
      }

      if (formValue.ownerShip == "") {
        setownerShipvallidate(true);
        setownerShipErrMsg(t("ownership-required"));
      } else {
        setownerShipvallidate(false);
        setownerShipErrMsg("");
      }

      if (formValue.companyRole == "") {
        setcompanyRolevallidate(true);
        setcompanyRoleErrMsg(t("company-role-required"));
      } else {
        setcompanyRolevallidate(false);
        setcompanyRoleErrMsg("");
      }

      if (formValue.politicalExposes == "yes" || selectAccess == "yes") {
        if (formValue.politicalFirstName == "") {
          setpoliticalFirstNamevallidate(true);
          setpoliticalFirstNameErrMsg(t("fname-required-kyb5"));
        } else {
          setpoliticalFirstNamevallidate(false);
          setpoliticalFirstNameErrMsg("");
        }

        if (formValue.politicalSurName == "") {
          setpoliticalSurNamevallidate(true);
          setpoliticalSurNameErrMsg(t("surname-required-kyb5"));
        } else {
          setpoliticalSurNamevallidate(false);
          setpoliticalSurNameErrMsg("");
        }
      } else {
        setpoliticalFirstNamevallidate(false);
        setpoliticalSurNamevallidate(false);
      }
      if (formValue.relativeStatus == "yes" || selectAssociate == "yes") {
        if (formValue.relativeFirstName == "") {
          setrelativeFirstNamevallidate(true);
          setrelativeFirstNameErrMsg(t("fname-required-kyb5"));
        } else {
          setrelativeFirstNamevallidate(false);
          setrelativeFirstNameErrMsg("");
        }

        if (formValue.relativeSurName == "") {
          setrelativeSurNamevallidate(true);
          setrelativeSurNameErrMsg(t("surname-required-kyb5"));
        } else {
          setrelativeSurNamevallidate(false);
          setrelativeSurNameErrMsg("");
        }
      } else {
        setrelativeFirstNamevallidate(false);
        setrelativeSurNamevallidate(false);
      }

      if (formValue.identityDocument == "") {
        setidentityDocumentvallidate(true);
        setidentityDocumentErrMsg("identityDocument is required !");
      } else {
        setidentityDocumentvallidate(false);
        setidentityDocumentErrMsg("");
      }

      if (formValue.addressProof == "") {
        setaddressProofvallidate(true);
        setaddressProofErrMsg(t("addressproof-required"));
      } else {
        setaddressProofvallidate(false);
        setaddressProofErrMsg("");
      }

      // if(formValue.beneficilaOwner.length>0){
      //   if (formValue.beneficialfirstName === "") {
      //     setbeneficialfirstNameErrMsg("firstName is a required field!");
      //     setbeneficialfirstNamevallidate(true);
      //   } else if (!/^[a-zA-Z]/.test(formValue.beneficialfirstName)) {
      //    setbeneficialfirstNameErrMsg("firstName must start with a letter !");
      //    setbeneficialfirstNamevallidate(true);
      //   } else if (formValue.beneficialfirstName.length < 3 || formValue.beneficialfirstName.length > 25) {
      //    setbeneficialfirstNameErrMsg("firstName must have an  3 to 25 characters !");
      //     setbeneficialfirstNamevallidate(true);
      //   } else if (!/^[a-zA-Z0-9_]+$/.test(formValue.beneficialfirstName)) {
      //       setbeneficialfirstNameErrMsg(
      //       "firstName can only contain letters, numbers, and underscores !");
      //     setbeneficialfirstNamevallidate(true);
      //   } else {
      //     setbeneficialfirstNamevallidate(false);
      //     setbeneficialfirstNameErrMsg("");
      //   }

      //   if (formValue.beneficialsurName === "") {
      //     setbeneficialsurNameErrMsg("surname is a required field!");
      //     setbeneficialsurNamevallidate(true);
      //   } else if (!/^[a-zA-Z]/.test(formValue.beneficialsurName)) {
      //    setbeneficialsurNameErrMsg("surname must start with a letter !");
      //    setbeneficialsurNamevallidate(true);
      //   } else if (formValue.beneficialsurName.length < 3 || formValue.beneficialsurName.length > 25) {
      //    setbeneficialsurNameErrMsg("surname must have an  3 to 25 characters !");
      //     setbeneficialsurNamevallidate(true);
      //   } else if (!/^[a-zA-Z0-9_]+$/.test(formValue.beneficialsurName)) {
      //     setbeneficialsurNameErrMsg(
      //       "surname can only contain letters, numbers, and underscores !");
      //     setbeneficialsurNamevallidate(true);
      //   } else {
      //     setbeneficialsurNamevallidate(false);
      //     setbeneficialsurNameErrMsg("");
      //   }

      //   if (formValue.beneficialDob == "") {
      //     setbeneficialdobvallidate(true);
      //     setbeneficialdobErrMsg("Date of birth is required !");
      //   } else {
      //     setbeneficialdobvallidate(false);
      //     setbeneficialdobErrMsg("");    }

      //   if (formValue.beneficialAddress == "") {
      //     setbeneficialaddressvallidate(true);
      //     setbeneficialaddressErrMsg("Address is required !");
      //   } else {
      //     setbeneficialaddressvallidate(false);
      //     setbeneficialaddressErrMsg("");    }

      //   if (formValue.beneficialcity == "") {
      //     setbeneficialcityvallidate(true);
      //     setbeneficialcityErrMsg("City is required !");
      //   } else {
      //     setbeneficialcityvallidate(false);
      //     setbeneficialcityErrMsg("");    }

      //   if (formValue.beneficialpostalCode == "") {
      //     setbeneficialpostalCodevallidate(true);
      //     setbeneficialpostalCodeErrMsg("Postal code is required !");
      //   } else {
      //     setbeneficialpostalCodevallidate(false);
      //     setbeneficialpostalCodeErrMsg("");    }

      //   if (formValue.beneficialcountry == "") {
      //     setbeneficialcountryvallidate(true);
      //     setbeneficialcountryErrMsg("Country is required !");
      //   } else {
      //     setbeneficialcountryvallidate(false);
      //     setbeneficialcountryErrMsg("");    }

      //   if (formValue.beneficialNationality == "") {
      //     setbeneficialnationalityvallidate(true);
      //     setbeneficialnationalityErrMsg("Nationality is required !");
      //   } else {
      //     setbeneficialnationalityvallidate(false);
      //     setbeneficialnationalityErrMsg("");    }

      //  if (formValue.beneficialownerShip == "") {
      //    setbeneficialownerShipvallidate(true);
      //    setbeneficialownerShipErrMsg("OwnerShip is required !");
      //  } else {
      //    setbeneficialownerShipvallidate(false);
      //    setbeneficialownerShipErrMsg("");    }

      //   if (formValue.beneficialcompanyRole == "") {
      //    setbeneficialcompanyRolevallidate(true);
      //    setbeneficialcompanyRoleErrMsg("Companyrole is required !");
      //  } else {
      //    setbeneficialcompanyRolevallidate(false);
      //    setbeneficialcompanyRoleErrMsg("");    }

      // if (formValue.beneficialpoliticalExposes=="Yes"){
      //   if (formValue.beneficialpoliticalFirstName == "") {
      //     setbeneficialpoliticalFirstNamevallidate(true);
      //     setbeneficialpoliticalFirstNameErrMsg("FirstName is required !");
      //   } else {
      //     setbeneficialpoliticalFirstNamevallidate(false);
      //     setbeneficialpoliticalFirstNameErrMsg("");    }

      //   if (formValue.beneficialpoliticalSurName == "") {
      //     setbeneficialpoliticalSurNamevallidate(true);
      //     setbeneficialpoliticalSurNameErrMsg("SurName is required !");
      //   } else {
      //     setbeneficialpoliticalSurNamevallidate(false);
      //     setbeneficialpoliticalSurNameErrMsg("");    }
      // }
      // if (formValue.beneficialrelativeStatus=="Yes"){
      //   if (formValue.beneficialrelativeFirstName == "") {
      //     setbeneficialrelativeFirstNamevallidate(true);
      //     setbeneficialrelativeFirstNameErrMsg("FirstName is required !");
      //   } else {
      //     setbeneficialrelativeFirstNamevallidate(false);
      //     setbeneficialrelativeFirstNameErrMsg("");    }

      //   if (formValue.beneficialrelativeSurName == "") {
      //     setbeneficialrelativeSurNamevallidate(true);
      //     setbeneficialrelativeSurNameErrMsg("SurName is required !");
      //   } else {
      //     setbeneficialrelativeSurNamevallidate(false);
      //     setbeneficialrelativeSurNameErrMsg("");    }
      // }
      // if (formValue.beneficialidentityDocument == "") {
      //   setbeneficialidentityDocumentvallidate(true);
      //   setbeneficialidentityDocumentErrMsg("identityDocument is required !");
      // } else {
      //   setbeneficialidentityDocumentvallidate(false);
      //   setbeneficialidentityDocumentErrMsg("");    }

      // if (formValue.beneficialaddressProof == "") {
      //   setbeneficialaddressProofvallidate(true);
      //   setbeneficialaddressProofErrMsg("addressProof is required !");
      // } else {
      //   setbeneficialaddressProofvallidate(false);
      //   setbeneficialaddressProofErrMsg("");    }
      // }

      // if(formValue.parentsCompany.length>0){
      // if (formValue.parentalregistrationtype === "") {
      //   setparentalregistrationtypeErrMsg("Register Type is a required field!");
      //   setparentalregistrationtypevallidate(true);
      // } else {
      //   setparentalregistrationtypevallidate(false);
      // }

      // if (formValue.parentalregistrationnumber === "") {
      //   setparentalregistrationnumberErrMsg("Register Number is a required field!");
      //   setparentalregistrationnumbervallidate(true);
      // } else {
      //   setparentalregistrationnumbervallidate(false);
      // }

      // if (formValue.parentalDateofformation === "") {
      //   setparentalDateofformationErrMsg("Date is a required field!");
      //   setparentalDateofformationvallidate(true);
      // } else {
      //   setparentalDateofformationvallidate(false);
      // }

      // if (formValue.parentalCountry === "") {
      //   setparentalCountryErrMsg("Country is a required field!");
      //   setparentalCountryvallidate(true);
      // } else {
      //   setparentalCountryvallidate(false);
      // }

      // if (formValue.parentalcorporateFormationproof=== "") {
      //   setcorporateFormErrMsg("  Corporation Proof is a required field!");
      //   setcorporateFormvallidate(true);
      // } else {
      //   setcorporateFormvallidate(false);
      // }

      // if (formValue.parentalcompanyproof=== "") {
      //   setcompanyFormErrMsg("  Company Proof is a required field!");
      //   setcompanyFormvallidate(true);
      // } else {
      //   setcompanyFormvallidate(false);
      // }

      // if (formValue.parentalownershipshare=== "") {
      //   setparentalownershipshareErrMsg("  Owner Ship is a required field!");
      //   setparentalownershipsharevallidate(true);
      // } else {
      //   setparentalownershipsharevallidate(false);
      // }

      // if (formValue.parentaladdressProof=== "") {
      //   setaddressFormErrMsg("  Address Proof is a required field!");
      //   setaddressFormvallidate(true);
      // } else {
      //   setaddressFormvallidate(false);
      // }
      // }

      if (formValue.organizalChart === "") {
        setorganizalChartErrMsg(t("chart-required-field"));
        setorganizalChartvallidate(true);
      } else {
        setorganizalChartvallidate(false);
      }
    } catch (err) {
      console.log("valldatio1err", err);
    }
  };

  //BENEFICIAL OPTION WORK===========

  const [beneficialOwners, setBeneficialOwners] = useState([]);

  // State to manage validation errors
  const [beneficialerrors, setbeneficialErrors] = useState([]);

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    beneficialOwners.forEach((owner, index) => {
      if (!owner.beneficialFirstName)
        newErrors[`beneficialFirstName_${index}`] = t("fname-required-kyb5");
      if (!owner.beneficialSurName)
        newErrors[`beneficialSurName_${index}`] = t("surname-required-kyb5");
      if (!owner.beneficialAddress)
        newErrors[`beneficialaddress_${index}`] = t("address-required");
      if (!owner.beneficialdob)
        newErrors[`beneficialdob_${index}`] = t("dob-required");
      if (!owner.beneficialpostalcode)
        newErrors[`beneficialpostalcode_${index}`] = t(
          "postal-code-required-kyb5"
        );
      if (!owner.beneficialCountry)
        newErrors[`country_${index}`] = t("country-required");
      if (!owner.beneficialNationality)
        newErrors[`nationality_${index}`] = t("nationality-required");
      if (!owner.beneficialOwnership)
        newErrors[`ownership_${index}`] = t("ownership-share-required");
      if (!owner.beneficialrole)
        newErrors[`beneficialrole_${index}`] = t("company-role-share-required");
      if (!owner.beneficialCity)
        newErrors[`beneficialcity_${index}`] = t("city-required");
      if (owner.isPolitical == "yes") {
        if (!owner.beneficialPoliticalFirstName)
          newErrors[`politicalFirstName_${index}`] = t("fname-required-kyb5");
        if (!owner.beneficialPoliticalSurName)
          newErrors[`politicalSurName_${index}`] = t("surname-required-kyb5");
      }
      if (owner.isrelative == "yes") {
        if (!owner.beneficialrelativeFirstName)
          newErrors[`relativeFirstName_${index}`] = t("fname-required-kyb5");
        if (!owner.beneficialrelativeSurName)
          newErrors[`relativeSurName_${index}`] = t("surname-required-kyb5");
      }
      if (!owner.beneficialidentityDocument && !beneficialidroofref.current)
        newErrors[`beneficialidentityDocument_${index}`] =
          t("id-proof-required");
      if (
        !owner.beneficialaddressDocument &&
        !beneficialaddressProofref.current
      )
        newErrors[`beneficialaddressDocument_${index}`] = t(
          "address-proof-required-kyb5"
        );
    });
    setbeneficialErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle changes in the input fields
  const handleBeneficialChange = (index, field, value) => {
    console.log(index, field, value, "index, field, value");
    const updatedOwners = [...beneficialOwners];
    updatedOwners[index] = { ...updatedOwners[index], [field]: value };
    setBeneficialOwners(updatedOwners);
    updatedOwners[index] = { ...updatedOwners[index], [field]: value };
    if (field === "isPolitical" && value === "no") {
      updatedOwners[index].beneficialPoliticalFirstName = "";
      updatedOwners[index].beneficialPoliticalSurName = "";
    }
    if (field === "isrelative" && value === "no") {
      updatedOwners[index].beneficialrelativeFirstName = "";
      updatedOwners[index].beneficialrelativeSurName = "";
    }
    setBeneficialOwners(updatedOwners);
  };

  // Add a new beneficial owner
  const handleAddBeneficial = () => {
    setParentCompanies([]);
    setBeneficialOwners([
      ...beneficialOwners,
      {
        beneficialFirstName: "",
        beneficialSurName: "",
        beneficialAddress: "",
        beneficialUnit: "",
        beneficialCity: "",
        beneficialCountry: "",
        beneficialNationality: "",
        hasControl: "yes",
        personaccount: "yes",
        isPolitical: "yes",
        isrelative: "yes",
        beneficialOwnership: "",
        beneficialPoliticalFirstName: "",
        beneficialPoliticalSurName: "",
        beneficialrelativeFirstName: "",
        beneficialrelativeSurName: "",
        beneficialaddressDocument: "",
        beneficialidentityDocument: "",
      },
    ]);
  };

  // Delete a beneficial owner
  const handlebenefitDelete = (index) => {
    const updatedOwners = beneficialOwners.filter((_, i) => i !== index);
    setBeneficialOwners(updatedOwners);
  };

  // const handleFileChange2 = (index, field, file) => {
  //   console.log(index, "index", field, "field", file, "file");
  //   const newbenificialCompanies = [...beneficialOwners];
  //   const fileExtension = file.name.split(".").at(-1).toLowerCase();
  //   const fileSize = file.size;
  //   const fileName = file.name;

  //   // Check file type and size
  //   if (fileExtension !== "png" && fileExtension !== "jpg" && fileExtension !== "jpeg") {
  //     toast.error("File does not support. You must use .png or .jpg or .jpeg ");
  //     return;
  //   } else if (fileSize > 10000000) {
  //     toast.error("Please upload a file smaller than 1 MB");
  //     return;
  //   }

  //   // Show loading indicators
  //   if (field === "beneficialaddressDocument") {
  //     setbeneficialaddressProofLoad(true);
  //   } else if (field === "beneficialidentityDocument") {
  //     setbeneficialidproofLoad(true);
  //   }

  //   const data = new FormData();
  //   data.append("file", file);
  //   data.append("upload_preset", env.upload_preset);
  //   data.append("cloud_name", env.cloud_name);

  //   // Upload the file to Cloudinary
  //   fetch(`https://api.cloudinary.com/v1_1/${env.cloud_name}/auto/upload`, {
  //     method: "post",
  //     body: data,
  //   })
  //     .then((resp) => resp.json())
  //     .then((data) => {
  //       if (data.secure_url) {
  //         // Update the correct beneficial owner's field with the uploaded image URL
  //         newbenificialCompanies[index][field] = data.secure_url;
  //         setBeneficialOwners(newbenificialCompanies);
  //          console.log(beneficialOwners,"--=-=-=-=-=");
  //         // Set valid state and name of the document based on field
  //         if (field === "beneficialaddressDocument") {
  //           setbeneficialaddressProofLoad(false);
  //           setbeneficialvalidaddressProof(2);
  //           if (fileExtension === "pdf" || fileExtension === "odt" || fileExtension === "doc") {
  //             setbeneficialvalidaddressProof(1);
  //             setbeneficialaddressProof(data.secure_url);

  //           }
  //           setbeneficialaddressproofname(fileName);
  //         } else if (field === "beneficialidentityDocument") {
  //           setbeneficialidproofLoad(false);
  //           setbeneficialvalididproof(2);
  //           if (fileExtension === "pdf" || fileExtension === "odt" || fileExtension === "doc") {
  //             setbeneficialvalididproof(1);
  //           }
  //           setbeneficialidproofname(fileName);
  //           setbeneficialidproof(data.secure_url);

  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Please try again later");
  //       if (field === "beneficialaddressDocument") {
  //         setbeneficialaddressProofLoad(false);
  //       } else if (field === "beneficialidentityDocument") {
  //         setbeneficialidproofLoad(false);
  //       }
  //     });
  // };

  const handleFileChange2 = (index, field, file) => {
    console.log(index, "index", field, "field", file, "file");
    const newbenificialCompanies = [...beneficialOwners];
    newbenificialCompanies[index][field] = file;
    setBeneficialOwners(newbenificialCompanies);
    console.log(beneficialOwners, "--=-=-=-=-=");

    const fileExtension = file.name.split(".").at(-1);
    const fileSize = file.size;
    const fileName = file.name;

    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error(t("file-does-not-support"));
    } else if (fileSize > 10000000) {
      toast.error(t("please-upload-file-smaller-than-1mb"));
    } else {
      field == "beneficialaddressProof"
        ? setbeneficialaddressProofLoad(true)
        : setbeneficialidproofLoad(true);
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", env.upload_preset);
      data.append("cloud_name", env.cloud_name);
      fetch(
        "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
        { method: "post", body: data }
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (field === "beneficialaddressProof") {
            setbeneficialaddressProofLoad(false);
            setbeneficialvalidaddressProof(2);
            if (
              fileExtension == "pdf" ||
              fileExtension == "odt" ||
              fileExtension == "doc"
            ) {
              setbeneficialvalidaddressProof(1);
            }
            setbeneficialaddressProof(data.secure_url);
            setbeneficialaddressproofname(file.name);
          }
          if (field === "beneficialidProof") {
            setbeneficialidproofLoad(false);
            setbeneficialvalididproof(2);
            if (
              fileExtension == "pdf" ||
              fileExtension == "odt" ||
              fileExtension == "doc"
            ) {
              setbeneficialvalididproof(1);
            }
            setbeneficialidproof(data.secure_url);
            setbeneficialidproofname(file.name);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(t("please-try-again-later"));
        });
    }
  };

  //PARENTAL OPTION WORK =========

  const [parentCompanies, setParentCompanies] = useState([]);
  const [errors, setErrors] = useState([]);

  const validateEntry = () => {
    const errors = {};
    parentCompanies.forEach((parent, index) => {
      if (!parent.parentalregistrationType) {
        errors[`parentalregistrationType_${index}`] = t(
          "registration-type-required"
        );
      }
      if (!parent.parentalregistrationNumber)
        errors[`parentalregistrationNumber_${index}`] = t(
          "registration-number-required"
        );
      if (!parent.parentaldateOfFormation)
        errors[`parentaldateOfFormation_${index}`] = t(
          "date-of-formation-required"
        );
      if (!parent.parentalcountryOfFormation)
        errors[`parentalcountryOfFormation_${index}`] = t(
          "country-required-kyb5"
        );
      if (
        !parent.parentalownershipShare ||
        parent.parentalownershipShare <= 0 ||
        parent.parentalownershipShare > 100
      )
        errors[`parentalownershipShare_${index}`] = t(
          "ownership-share-must-per-b/w-1-100"
        );
      if (
        !parent.parentalcorporateFormationProof &&
        !corporateFormationproofref.current
      )
        errors[`parentalcorporateFormationProof_${index}`] = t(
          "corporate-formation-required"
        );
      if (!parent.parentalcompanyShareRegistry && !companyproofref.current)
        errors[`parentalcompanyShareRegistry_${index}`] = t(
          "company-share-registry-required"
        );
      if (!parent.parentaladdressProof && !parentaladdressProofref.current)
        errors[`parentaladdressProof_${index}`] = t(
          "business-operating-address-required"
        );
    });

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddParentCompany = () => {
    setBeneficialOwners([]);
    setParentCompanies([
      ...parentCompanies,
      {
        parentalregistrationType: "",
        parentalregistrationNumber: "",
        parentaldateOfFormation: "",
        parentalcountryOfFormation: "",
        parentalownershipShare: "",
        parentalcorporateFormationProof: "",
        parentalcompanyShareRegistry: "",
        parentaladdressProof: "",
      },
    ]);
  };

  const handleRemoveParentCompany = (index) => {
    const updateParents = parentCompanies.filter((_, i) => i !== index);
    setParentCompanies(updateParents);
  };

  const handleInputChange = (index, field, value) => {
    const newParentCompanies = [...parentCompanies];
    newParentCompanies[index][field] = value;
    setParentCompanies(newParentCompanies);
  };

  const handleFileChange = (index, field, file) => {
    console.log(index, "index", field, "field", file, "file");
    const newParentCompanies = [...parentCompanies];
    // newParentCompanies[index][field] = file.name;
    // setParentCompanies(newParentCompanies);
    console.log(newParentCompanies, "newParentCompanies");
    const fileExtension = file.name.split(".").at(-1);
    const fileSize = file.size;
    const fileName = file.name;

    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"
    ) {
      toast.error(t("file-does-not-support"));
    } else if (fileSize > 10000000) {
      toast.error(t("please-upload-file-smaller-than-1mb"));
    } else {
      field == "parentalcorporateFormationProof"
        ? setcorporateFormationproofLoad(true)
        : field == "parentalcompanyShareRegistry"
        ? setcompanyproofLoad(true)
        : setparentaladdressProofLoad(true);
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", env.upload_preset);
      data.append("cloud_name", env.cloud_name);
      fetch(
        "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
        { method: "post", body: data }
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (field === "parentalcorporateFormationProof") {
            setcorporateFormationproofLoad(false);
            setvalidcorporateFormationproof(2);
            if (
              fileExtension == "pdf" ||
              fileExtension == "odt" ||
              fileExtension == "doc"
            ) {
              setvalidcorporateFormationproof(1);
            }
            setcorporateFormationproof(data.secure_url);
            newParentCompanies[index][field] = data.secure_url;
            setParentCompanies(newParentCompanies);
            setcorporateFormationproofname(file.name);
          } else if (field === "parentalcompanyShareRegistry") {
            setcompanyproofLoad(false);
            setvalidcompanyproof(2);
            if (
              fileExtension == "pdf" ||
              fileExtension == "odt" ||
              fileExtension == "doc"
            ) {
              setvalidcompanyproof(1);
            }
            setcompanyproofname(file.name);
            setcompanyproof(data.secure_url);
            newParentCompanies[index][field] = data.secure_url;
            setParentCompanies(newParentCompanies);
          } else {
            setparentaladdressProofLoad(false);
            setparentalvalidaddressProof(2);
            if (
              fileExtension == "pdf" ||
              fileExtension == "odt" ||
              fileExtension == "doc"
            ) {
              setparentalvalidaddressProof(1);
            }
            setparentaladdressproofname(file.name);
            setparentaladdressProof(data.secure_url);
            newParentCompanies[index][field] = data.secure_url;
            setParentCompanies(newParentCompanies);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(t("please-try-again-later"));
        });
    }
  };
  useEffect(() => {
    getStep();
  }, [0]);

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      console.log(resp, "=-=-=-");
      if (resp.data !== null) {
        var kybdata = resp.data;
        console.log(kybdata, "kybdata");
        formValue.firstName = kybdata.firstName;
        formValue.surName = kybdata.surName;
        formValue.Dob = kybdata.Dob;
        formValue.Address = kybdata.Address;
        formValue.unit = kybdata.unit;
        formValue.city = kybdata.city;
        formValue.postalCode = kybdata.postalCode;
        formValue.country = kybdata.country;
        formValue.Nationality = kybdata.Nationality;
        formValue.ownerShip = kybdata.ownerShip;
        formValue.companyRole = kybdata.companyRole;
        formValue.maintainStatuis = kybdata.maintainStatuis;
        formValue.accountAccess = kybdata.accountAccess;
        formValue.politicalExposes = kybdata.politicalExposes;
        setSelectAccess(kybdata.politicalExposes);
        formValue.politicalFirstName = kybdata.politicalFirstName;
        formValue.politicalSurName = kybdata.politicalSurName;
        formValue.relativeStatus = kybdata.relativeStatus;
        setSelectAssociate(kybdata.relativeStatus);
        setvalididproof(2);
        setvalidaddressProof(2);
        formValue.relativeFirstName = kybdata.relativeFirstName;
        formValue.relativeSurName = kybdata.relativeSurName;
        formValue.identityDocument = kybdata.identityDocumentName;
        formValue.addressProof = kybdata.addressProofName;
        setidproof(kybdata.identityDocument);
        setaddressProof(kybdata.addressProof);
        setorganizeidproof(kybdata.organizalChart);
        setorganizevalididproof(2);
        formValue.companyphysicalstatus = kybdata.companyphysicalstatus;
        formValue.legalexemptstatus = kybdata.legalexemptstatus;
        setBeneficialOwners(kybdata.beneficilaOwner);
        setParentCompanies(kybdata.parentsCompany);
        setbeneficialvalidaddressProof(2);
        setbeneficialvalididproof(2);
        console.log(
          beneficialOwners,
          kybdata.parentsCompany,
          "formValue.identityDocumentformValue.identityDocument"
        );
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      vallidation1();
      if (
        firstNamevallidateref.current == false &&
        surNamevallidateref.current == false &&
        dobvallidateref.current == false &&
        addressvallidateref.current == false &&
        cityvallidateref.current == false &&
        countryvallidateref.current == false &&
        postalCodevallidateref.current == false &&
        nationalityvallidateref.current == false &&
        ownerShipvallidateref.current == false &&
        identityDocumentvallidateref.current == false &&
        addressProofvallidateref.current == false &&
        formValue.politicalExposes == "yes"
          ? politicalFirstNamevallidateref.current == false &&
            politicalSurNamevallidateref.current == false
          : politicalFirstNamevallidateref.current == false &&
            politicalSurNamevallidateref.current == false &&
            formValue.relativeStatus == "yes"
          ? relativeFirstNamevallidateref.current == false &&
            relativeSurNamevallidateref.current == false
          : relativeFirstNamevallidateref.current == false &&
            relativeSurNamevallidateref.current == false
      ) {
        vallidation1(formValue);
        var vallidate_field = "";
        if (beneficialOwners.length > 0) {
          const isValid = validateForm();
          var vallidate_field = isValid;
          console.log(isValid, "=-=-=-=-=");
        } else if (parentCompanies.length > 0) {
          const isValid2 = validateEntry();
          var vallidate_field = isValid2;
          console.log("=-=-=-=-=");
        }

        if (vallidate_field == true) {
          var obj = {
            firstName: formValue.firstName,
            surName: formValue.surName,
            Dob: formValue.Dob,
            Address: formValue.Address,
            unit: formValue.unit,
            city: formValue.city,
            postalCode: formValue.postalCode,
            country: formValue.country,
            Nationality: formValue.Nationality,
            ownerShip: formValue.ownerShip,
            companyRole: formValue.companyRole,
            maintainStatuis: formValue.maintainStatuis,
            accountAccess: formValue.accountAccess,
            politicalExposes: formValue.politicalExposes,
            politicalFirstName: formValue.politicalFirstName,
            politicalSurName: formValue.politicalSurName,
            relativeStatus: formValue.relativeStatus,
            relativeFirstName: formValue.relativeFirstName,
            relativeSurName: formValue.relativeSurName,
            identityDocument: idproofref.current,
            addressProof: addressProofref.current,
            identityDocumentName: idproofnameref.current,
            addressProofName: addressproofnameref.current,
            companyphysicalstatus: !!formValue.companyphysicalstatus
              ? formValue.companyphysicalstatus
              : isPhysical,
            legalexemptstatus: !!formValue.legalexemptstatus
              ? formValue.legalexemptstatus
              : isLegal,
            beneficilaOwner: beneficialOwners,
            parentsCompany: parentCompanies,
            organizalChart: organizeidroofref.current,
            verifiedSteps: "Step-5",
          };
          var data = {
            apiUrl: apiService.kybfifth,
            payload: obj,
          };
          var resp = await postMethod(data);
          if (resp.status == true) {
            toast.success(resp.Message);
            navigate("/KypInformation6");
          } else {
            toast.error(resp.Message);
          }
        }
      }
    } catch (err) {
      console.log(err, "=--=-=-=-");
    }

    // else{
    //   console.log("Not Okay......")

    // }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main kyp_information5 information_end">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KypInformation4">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> {t("back")}
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">5</span>
                    {t("authorized-accessor-ownership-details")}
                  </h3>
                  <div class="accordion kyp-accordian" id="accordionExample">
                    {/* Person opening the account */}
                    <div class="accordion-item kyp-accor-header">
                      <h2
                        class="accordion-header kyp-accor-header"
                        id="headingOne"
                      >
                        <button
                          class="accordion-button kyp-accor-header"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t("person-opening-account")}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="col-lg-12">
                            <div className="input-groups fiat_deposit_name_details">
                              <div className="fiat_deposit_detail">
                                <h6 className="input-label">
                                  {t("first-name")}
                                </h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="disable"
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleChange}
                                    placeholder={t("enter-fname-kyb5")}
                                  />
                                </div>
                                {firstNamevallidateref.current && (
                                  <p className="errorcss">
                                    {firstNameErrMsgref.current}
                                  </p>
                                )}
                              </div>
                              <div className="fiat_deposit_detail">
                                <h6 className="input-label">{t("surname")}</h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="disable"
                                    name="surName"
                                    value={surName}
                                    onChange={handleChange}
                                    placeholder={t("enter-surname-kyb5")}
                                  />
                                </div>
                                {surNamevallidateref.current && (
                                  <p className="errorcss">
                                    {surNameErrMsgref.current}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="input-groups icons">
                              <h6 className="input-label">
                                {t("date-of-birth")}
                              </h6>
                              <div className="input-field ref_input">
                                <input
                                  type="date"
                                  name="Dob"
                                  value={Dob}
                                  onChange={handleChange}
                                  className=""
                                  placeholder="MM/DD/YYYY"
                                />
                              </div>
                              {dobvallidateref.current && (
                                <p className="errorcss">
                                  {dobErrMsgref.current}
                                </p>
                              )}
                            </div>
                            <div className="input-groups fiat_deposit_name_details">
                              <div className="fiat_deposit_detail">
                                <h6 className="input-label">{t("address")}</h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="text"
                                    name="Address"
                                    value={Address}
                                    onChange={handleChange}
                                    className=""
                                    placeholder={t("enter-date-of-formation")}
                                  />
                                </div>
                                {addressvallidateref.current && (
                                  <p className="errorcss">
                                    {addressErrMsgref.current}
                                  </p>
                                )}
                              </div>
                              <div className="fiat_deposit_detail">
                                <h6 className="input-label">
                                  {t("unit#(optional)")}
                                </h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="number"
                                    name="unit"
                                    value={unit}
                                    onChange={handleChange}
                                    className=""
                                    placeholder={t("unit#")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="input-groups fiat_deposit_name_details">
                              <div className="fiat_deposit_detail city_content">
                                <h6 className="input-label">{t("city")}</h6>
                                <div className="input-field ref_input kyc_country_dropdown">
                                  <input
                                    type="text"
                                    name="city"
                                    value={city}
                                    onChange={handleChange}
                                    className=""
                                    placeholder={t("city#")}
                                  />
                                </div>
                                {cityvallidateref.current && (
                                  <p className="errorcss">
                                    {cityErrMsgref.current}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="input-groups fiat_deposit_name_details">
                              <div className="fiat_deposit_detail">
                                <h6 className="input-label">
                                  {t("zip-postal-code")}
                                </h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="number"
                                    name="postalCode"
                                    value={postalCode}
                                    onChange={handleChange}
                                    className=""
                                    placeholder={t("enter-zip-postal-code")}
                                  />
                                </div>
                                {postalCodevallidateref.current && (
                                  <p className="errorcss">
                                    {postalCodeErrMsgref.current}
                                  </p>
                                )}
                              </div>
                              <div className="fiat_deposit_detail">
                                <h6 className="input-label">{t("country")}</h6>
                                <div className="custom-select-stake kyc-verify my-2 w-100">
                                  <div className="input-groups select-wrapper ">
                                    <Select
                                      options={options}
                                      value={options.find(
                                        (option) => option.value === country
                                      )}
                                      onChange={handleCountryChange}
                                      placeholder={t("select-country")}
                                      styles={colourStyles}
                                      className="reg-select-dropdown "
                                    />
                                  </div>
                                </div>
                                {countryvallidateref.current && (
                                  <p className="errorcss">
                                    {countryErrMsgref.current}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="input-groups icons">
                              <h6 className="input-label">
                                {t("nationality")}
                              </h6>
                              <div className="custom-select-stake kyc-verify my-2 w-100">
                                <div className="input-groups select-wrapper ">
                                  <Select
                                    options={options}
                                    value={options.find(
                                      (option) => option.value === Nationality
                                    )}
                                    onChange={handleNationalityChange}
                                    placeholder={t("select-nationality")}
                                    styles={colourStyles}
                                    className="reg-select-dropdown "
                                  />
                                </div>
                              </div>

                              {/* <div className="input-field ref_input kyc_country_dropdown">
                              </div> */}
                            </div>
                            {nationalityvallidateref.current && (
                              <p className="errorcss">
                                {nationalityErrMsgref.current}
                              </p>
                            )}
                            <div className="input-groups fiat_deposit_name_details">
                              <div className="fiat_deposit_detail city_content">
                                <h6 className="input-label">
                                  {t("ownership-share-%")}
                                </h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="text"
                                    name="ownerShip"
                                    value={ownerShip}
                                    onChange={handleChange}
                                    className=""
                                    placeholder="0"
                                  />
                                </div>
                              </div>
                            </div>
                            {ownerShipvallidateref.current && (
                              <p className="errorcss">
                                {ownerShipErrMsgref.current}
                              </p>
                            )}
                            <div className="input-groups fiat_deposit_name_details">
                              <div className="fiat_deposit_detail city_content">
                                <h6 className="input-label">
                                  {t("title/role-at-company")}
                                </h6>
                                <div className="input-field ref_input">
                                  <input
                                    type="text"
                                    name="companyRole"
                                    value={companyRole}
                                    onChange={handleChange}
                                    className=""
                                    placeholder={t("enter-title-role-company")}
                                  />
                                </div>
                                {companyRolevallidateref.current && (
                                  <p className="errorcss">
                                    {companyRoleErrMsgref.current}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="access_act_text">
                              <h3>
                                {t(
                                  "does-this-person-maintain-control-over-company"
                                )}
                              </h3>
                              <div className="access_act_text_btn mt-4">
                                <button
                                  onClick={yesBtnClick}
                                  className={
                                    selectBtn === "yes"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("yes")}
                                </button>
                                <button
                                  onClick={noBtnClick}
                                  className={
                                    selectBtn === "no"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("no")}
                                </button>
                              </div>
                            </div>
                            <div className="access_act_text">
                              <h3>{t("will-this-person-access")}</h3>
                              <div className="access_act_text_btn">
                                <button
                                  onClick={handleYesAccess}
                                  className={
                                    selectPerson === "yes"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("yes")}
                                </button>
                                <button
                                  onClick={handleNoAccess}
                                  className={
                                    selectPerson === "no"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("no")}
                                </button>
                              </div>
                            </div>
                            <div className="access_act_text">
                              <p className="holder_content">
                                {t(
                                  "are-any-account-holders-politically-exposed-person"
                                )}
                              </p>
                              <div className="access_act_text_btn">
                                <button
                                  onClick={accountYes}
                                  className={
                                    selectAccess === "yes"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("yes")}
                                </button>
                                <button
                                  onClick={accountNo}
                                  className={
                                    selectAccess === "no"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("no")}
                                </button>
                              </div>
                            </div>
                            {selectAccess === "no" ? (
                              ""
                            ) : (
                              <div className="input-groups fiat_deposit_name_details">
                                <div className="fiat_deposit_detail">
                                  <h6 className="input-label clr-neutral">
                                    {t("if-yes-specify-name-surname")}
                                  </h6>
                                  <div className="input-field ref_input">
                                    <input
                                      type="text"
                                      name="politicalFirstName"
                                      value={politicalFirstName}
                                      onChange={handleChange}
                                      className=""
                                      placeholder={t("enter-fname-kyb5")}
                                    />
                                  </div>
                                  {politicalFirstNamevallidateref.current && (
                                    <p className="errorcss">
                                      {politicalFirstNameErrMsgref.current}
                                    </p>
                                  )}
                                </div>
                                <div className="fiat_deposit_detail">
                                  <h6 className="input-label"></h6>
                                  <div className="input-field ref_input">
                                    <input
                                      type="text"
                                      name="politicalSurName"
                                      value={politicalSurName}
                                      onChange={handleChange}
                                      className=""
                                      placeholder={t("enter-surname-kyb5")}
                                    />
                                  </div>
                                  {politicalSurNamevallidateref.current && (
                                    <p className="errorcss">
                                      {politicalSurNameErrMsgref.current}
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="access_act_text">
                              <p className="holder_content">
                                {t(
                                  "are-you-relative-associate-any-person-who-currently-holds"
                                )}
                              </p>

                              <div className="access_act_text_btn">
                                <button
                                  onClick={handleYesAssociate}
                                  className={
                                    selectAssociate === "yes"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("yes")}
                                </button>
                                <button
                                  onClick={handleNoAssociate}
                                  className={
                                    selectAssociate === "no"
                                      ? "yes_button"
                                      : "no_button"
                                  }
                                >
                                  {t("no")}
                                </button>
                              </div>
                            </div>
                            {selectAssociate === "no" ? (
                              ""
                            ) : (
                              <div className="input-groups fiat_deposit_name_details">
                                <div className="fiat_deposit_detail">
                                  <h6 className="input-label clr-neutral">
                                    {t("if-yes-specify-name-surname")}
                                  </h6>
                                  <div className="input-field ref_input">
                                    <input
                                      type="text"
                                      name="relativeFirstName"
                                      value={relativeFirstName}
                                      onChange={handleChange}
                                      className=""
                                      placeholder={t("enter-fname-kyb5")}
                                    />
                                  </div>
                                  {relativeFirstNamevallidateref.current && (
                                    <p className="errorcss">
                                      {relativeFirstNameErrMsgref.current}
                                    </p>
                                  )}
                                </div>
                                <div className="fiat_deposit_detail">
                                  <h6 className="input-label"></h6>
                                  <div className="input-field ref_input">
                                    <input
                                      type="text"
                                      name="relativeSurName"
                                      value={relativeSurName}
                                      onChange={handleChange}
                                      className=""
                                      placeholder={t("enter-surname-kyb5")}
                                    />
                                  </div>
                                  {relativeSurNamevallidateref.current && (
                                    <p className="errorcss">
                                      {relativeSurNameErrMsgref.current}
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="access_act_text">
                              <h3>{t("identity-document")}</h3>
                            </div>

                            <div className="driver_license_content">
                              <input
                                id="check-comp"
                                type="checkbox"
                                // className="checkbox-round"
                                className={
                                  idproofref.current == ""
                                    ? "checkbox-round"
                                    : "checkbox-round-checked"
                                }
                                // onClick={handleLicense}
                              />
                              {/* <label htmlFor="check-license">  Driver's license</label>
                    <i class="ri-arrow-down-s-line"></i> */}
                              <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                                <Select
                                  options={documentOptions}
                                  onChange={handleDocumentChange}
                                  placeholder={t("select-identity-document")}
                                  value={documentOptions.find(
                                    (option) =>
                                      option.value === identityDocument
                                  )}
                                  className=""
                                  styles={colourStyles}
                                />
                              </div>
                            </div>

                            {identityDocument != "" && (
                              <>
                                <div className="driver_license_doc">
                                  <div className="upload">
                                    {idproofLoad == false ? (
                                      valididproof == 0 ? (
                                        <>
                                          <div className="inner_frst_display">
                                            <i class="ri-upload-2-line"></i>
                                            <p> {t("click-to-upload-file")}</p>
                                          </div>
                                        </>
                                      ) : valididproof == 1 ? (
                                        <i class="bi bi-file-earmark-bar-graph"></i>
                                      ) : (
                                        <img
                                          src={idproofref.current}
                                          className="up_im_past"
                                          alt="National Id Front"
                                        />
                                      )
                                    ) : (
                                      <div className="inner_frst_display">
                                        <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                      </div>
                                    )}

                                    <input
                                      type="file"
                                      name="image"
                                      className="image_upload_kyc"
                                      onChange={(e) =>
                                        imageUpload(
                                          "identityDocument",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                    {idproofnameref.current == "" ? (
                                      ""
                                    ) : (
                                      <div className="">
                                        <input
                                          className="proofs_name"
                                          disabled
                                          value={idproofnameref.current}
                                        ></input>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            {identityDocumentvallidateref.current && (
                              <p className="errorcss">
                                {identityDocumentErrMsgref.current}
                              </p>
                            )}
                            <div className="driver_license_doc">
                              <div className="access_act_text">
                                <h3> {t("proof-of-address")}</h3>
                                <p className="clr-neutral">
                                  {t("Should-have-the-same-address")}
                                </p>
                              </div>
                            </div>

                            <div className="driver_license_content">
                              <input
                                id="check-proof"
                                type="checkbox"
                                className={
                                  addressProofref.current == ""
                                    ? "checkbox-round"
                                    : "checkbox-round-checked"
                                }
                                // onClick={handleCredit}
                              />
                              {/* <label htmlFor="custom-credit"> Credit Card Statement  <i class="ri-arrow-down-s-line"></i></label> */}
                              <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                                <Select
                                  options={addressProofOptions}
                                  onChange={handleAddressProofChange}
                                  placeholder={t("select-address-proof")}
                                  value={addressProofOptions.find(
                                    (option) => option.value === addressProof
                                  )}
                                  className=""
                                  styles={colourStyles}
                                />
                              </div>
                            </div>
                            {addressProof != "" && (
                              <div className="driver_license_doc">
                                <div className="upload">
                                  {addressProofLoad == false ? (
                                    validaddressProof == 0 ? (
                                      <>
                                        <div className="inner_frst_display">
                                          <i class="ri-upload-2-line"></i>
                                          <p>{t("click-to-upload-file")}</p>
                                        </div>
                                      </>
                                    ) : validaddressProof == 1 ? (
                                      <i class="bi bi-file-earmark-bar-graph"></i>
                                    ) : (
                                      <img
                                        src={addressProofref.current}
                                        className="up_im_past"
                                        alt="National Id Front"
                                      />
                                    )
                                  ) : (
                                    <div className="inner_frst_display">
                                      <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                    </div>
                                  )}

                                  <input
                                    type="file"
                                    name="image"
                                    className="image_upload_kyc"
                                    onChange={(e) =>
                                      imageUpload(
                                        "addressProof",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  {addressproofnameref.current == "" ? (
                                    ""
                                  ) : (
                                    <div className="">
                                      <input
                                        className="proofs_name"
                                        disabled
                                        value={addressproofnameref.current}
                                      ></input>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {addressProofvallidateref.current && (
                              <p className="errorcss">
                                {addressProofErrMsgref.current}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Beneficial Ownership */}
                    <div class="accordion-item kyp-accor-header">
                      <h2
                        class="accordion-header kyp-accor-header"
                        id="headingTwo"
                      >
                        <button
                          class="accordion-button collapsed kyp-accor-header"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {t("beneficial-ownership")}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="access_act_text">
                            <h3>
                              {t(
                                "is-this-address-where-your-company-physically-conducts"
                              )}
                            </h3>
                            <div className="access_act_text_btn mt-4">
                              <button
                                onClick={handleYesPhy}
                                className={
                                  isPhysical === "yes"
                                    ? "yes_button"
                                    : "no_button"
                                }
                              >
                                {t("yes")}
                              </button>
                              <button
                                onClick={handleNoPhy}
                                className={
                                  isPhysical === "no"
                                    ? "yes_button"
                                    : "no_button"
                                }
                              >
                                {t("no")}
                              </button>
                            </div>
                          </div>
                          <div className="access_act_text">
                            <h3>
                              {t("are-you-legally-exempt-providing-beneficial")}
                            </h3>
                            <div className="access_act_text_btn mt-4">
                              <button
                                onClick={handleYesLegal}
                                className={
                                  isLegal === "yes" ? "yes_button" : "no_button"
                                }
                              >
                                {t("yes")}
                              </button>
                              <button
                                onClick={handleNoLegal}
                                className={
                                  isLegal === "no" ? "yes_button" : "no_button"
                                }
                              >
                                {t("no")}
                              </button>
                            </div>
                          </div>
                          <div className="access_act_text mt-4">
                            <p className="clr-neutral">
                              {t("if-your-company-registered-united-states-uk")}
                            </p>
                            <p className="clr-neutral">
                              {t(
                                "if-your-company-registered-any-ohter-jurisdication"
                              )}
                            </p>
                            <p className="clr-neutral">
                              {t("if-no-natural-person")}
                            </p>
                            <p className="clr-neutral">
                              {t("you-can-skip-this-question")}
                            </p>
                          </div>
                          <div className="add_kyp_contents">
                            <div
                              class="add_compant_content cur-pointer"
                              onClick={handleAddBeneficial}
                              // onClick={handleBenefitClick}
                            >
                              <i class="ri-add-circle-line"></i>
                              <h4>{t("add-beneficial-owner")}</h4>
                            </div>

                            <div
                              class="add_compant_content cur-pointer"
                              onClick={handleAddParentCompany}
                            >
                              <i class="ri-add-circle-line"></i>
                              <h4>{t("add-parent-company")}</h4>
                            </div>
                          </div>

                          {/* =this is beneficial ownership */}

                          {beneficialOwners.map((owner, index) => {
                            console.log(owner);
                            return (
                              <div key={index}>
                                <div className="beneficial_ownership">
                                  <p>
                                    {t("beneficial-ownership")} {index + 1}{" "}
                                  </p>
                                  <div className="delete_text1">
                                    <div
                                      className="delete_text1 mt-0 cursor-pointer"
                                      onClick={() => handlebenefitDelete(index)}
                                    >
                                      <i className="ri-delete-bin-6-line"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail">
                                      <h6 className="input-label">
                                        {t("first-name")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="text"
                                          value={owner.beneficialFirstName}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialFirstName",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t("enter-fname-kyb5")}
                                        />
                                      </div>

                                      {beneficialerrors[
                                        `beneficialFirstName_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `beneficialFirstName_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="fiat_deposit_detail">
                                      <h6 className="input-label">
                                        {t("surname")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="text"
                                          value={owner.beneficialSurName}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialSurName",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t("enter-lastname")}
                                        />
                                      </div>

                                      {beneficialerrors[
                                        `beneficialSurName_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `beneficialSurName_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="input-groups icons">
                                    <h6 className="input-label">
                                      {t("date-of-birth")}
                                    </h6>
                                    <div className="input-field ref_input">
                                      <input
                                        type="date"
                                        value={owner.beneficialdob}
                                        onChange={(e) =>
                                          handleBeneficialChange(
                                            index,
                                            "beneficialdob",
                                            e.target.value
                                          )
                                        }
                                        placeholder="MM/DD/YYYY"
                                      />
                                    </div>

                                    {beneficialerrors[
                                      `beneficialdob_${index}`
                                    ] && (
                                      <p className="errorcss">
                                        {
                                          beneficialerrors[
                                            `beneficialdob_${index}`
                                          ]
                                        }
                                      </p>
                                    )}
                                  </div>
                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail">
                                      <h6 className="input-label">
                                        {t("address")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="text"
                                          value={owner.beneficialAddress}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialAddress",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t(
                                            "enter-date-of-formation"
                                          )}
                                        />
                                      </div>
                                      {beneficialerrors[
                                        `beneficialaddress_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `beneficialaddress_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>

                                    <div className="fiat_deposit_detail">
                                      <h6 className="input-label">
                                        {t("unit#(optional)")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="number"
                                          value={owner.beneficialUnit}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialUnit",
                                              e.target.value
                                            )
                                          }
                                          className=""
                                          placeholder={t("unit#")}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("city")}
                                      </h6>
                                      <div className="input-field ref_input kyc_country_dropdown">
                                        <input
                                          type="text"
                                          value={owner.beneficialCity}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialCity",
                                              e.target.value
                                            )
                                          }
                                          className=""
                                          placeholder={t(
                                            "enter-date-of-formation"
                                          )}
                                        />
                                      </div>
                                      {beneficialerrors[
                                        `beneficialcity_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `beneficialcity_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail">
                                      <h6 className="input-label">
                                        {t("zip-postal-code")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="number"
                                          value={owner.beneficialpostalcode}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialpostalcode",
                                              e.target.value
                                            )
                                          }
                                          className=""
                                          placeholder={t(
                                            "enter-zip-postal-code"
                                          )}
                                        />
                                      </div>
                                      {beneficialerrors[
                                        `beneficialpostalcode_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `beneficialpostalcode_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>

                                    <div className="fiat_deposit_detail">
                                      <h6 className="input-label">
                                        {t("country")}
                                      </h6>
                                      {/* <div className="input-field ref_input kyc_country_dropdown">
                                      </div> */}
                                      <div className="custom-select-stake kyc-verify my-2 w-100">
                                        <div className="input-groups select-wrapper ">
                                          <Select
                                            options={options}
                                            value={options.find(
                                              (option) =>
                                                option.value ===
                                                owner.beneficialCountry
                                            )}
                                            onChange={(option) =>
                                              handleBeneficialChange(
                                                index,
                                                "beneficialCountry",
                                                option.value
                                              )
                                            }
                                            placeholder={t("select-country")}
                                            styles={colourStyles}
                                            className="reg-select-dropdown "
                                          />
                                        </div>
                                      </div>
                                      {beneficialerrors[`country_${index}`] && (
                                        <p className="errorcss">
                                          {beneficialerrors[`country_${index}`]}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="input-groups icons">
                                    <h6 className="input-label">
                                      {t("nationality")}
                                    </h6>
                                    {/* <div className="input-field ref_input kyc_country_dropdown">
                                    </div> */}
                                    <div className="custom-select-stake kyc-verify my-2 w-100">
                                      <div className="input-groups select-wrapper ">
                                        <Select
                                          options={options}
                                          value={options.find(
                                            (option) =>
                                              option.value ===
                                              owner.beneficialNationality
                                          )}
                                          onChange={(option) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialNationality",
                                              option.value
                                            )
                                          }
                                          placeholder={t("select-nationality")}
                                          styles={colourStyles}
                                          className="reg-select-dropdown "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {beneficialerrors[`nationality_${index}`] && (
                                    <p className="errorcss">
                                      {beneficialerrors[`nationality_${index}`]}
                                    </p>
                                  )}
                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("ownership-share-%")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="number"
                                          value={owner.beneficialOwnership}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialOwnership",
                                              e.target.value
                                            )
                                          }
                                          className=""
                                          placeholder="0"
                                        />
                                      </div>
                                      {beneficialerrors[
                                        `ownership_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `ownership_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("title/role-at-company")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="text"
                                          value={owner.beneficialrole}
                                          onChange={(e) =>
                                            handleBeneficialChange(
                                              index,
                                              "beneficialrole",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t(
                                            "enter-title-role-company"
                                          )}
                                        />
                                      </div>
                                      {beneficialerrors[
                                        `beneficialrole_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            beneficialerrors[
                                              `beneficialrole_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="access_act_text">
                                    <h3>
                                      {t(
                                        "does-this-person-maintain-control-over-company"
                                      )}
                                    </h3>
                                    <div className="access_act_text_btn mt-4">
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "hasControl",
                                            "yes"
                                          )
                                        }
                                        className={
                                          owner.hasControl === "yes"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("yes")}
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "hasControl",
                                            "no"
                                          )
                                        }
                                        className={
                                          owner.hasControl === "no"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("no")}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="access_act_text">
                                    <h3>
                                      {t(
                                        "will-this-person-have-access-account"
                                      )}
                                    </h3>

                                    <div className="access_act_text_btn mt-3">
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "personaccount",
                                            "yes"
                                          )
                                        }
                                        className={
                                          owner.personaccount === "yes"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("yes")}
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "personaccount",
                                            "no"
                                          )
                                        }
                                        className={
                                          owner.personaccount === "no"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("no")}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="access_act_text">
                                    <p className="holder_content">
                                      {t(
                                        "are-any-account-holders-politically-exposed-person"
                                      )}
                                    </p>
                                    <div className="access_act_text_btn">
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "isPolitical",
                                            "yes"
                                          )
                                        }
                                        className={
                                          owner.isPolitical === "yes"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("yes")}
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "isPolitical",
                                            "no"
                                          )
                                        }
                                        className={
                                          owner.isPolitical === "no"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("no")}
                                      </button>
                                    </div>
                                  </div>
                                  {owner.isPolitical === "yes" ? (
                                    <div className="input-groups fiat_deposit_name_details">
                                      <div className="fiat_deposit_detail">
                                        <h6 className="input-label clr-neutral">
                                          {t("if-yes-specify-name-surname")}
                                        </h6>
                                        <div className="input-field ref_input">
                                          <input
                                            type="text"
                                            value={
                                              owner.beneficialPoliticalFirstName
                                            }
                                            onChange={(e) =>
                                              handleBeneficialChange(
                                                index,
                                                "beneficialPoliticalFirstName",
                                                e.target.value
                                              )
                                            }
                                            className=""
                                            placeholder={t("enter-fname-kyb5")}
                                          />
                                        </div>
                                        {beneficialerrors[
                                          `politicalFirstName_${index}`
                                        ] && (
                                          <p className="errorcss">
                                            {
                                              beneficialerrors[
                                                `politicalFirstName_${index}`
                                              ]
                                            }
                                          </p>
                                        )}
                                      </div>

                                      <div className="fiat_deposit_detail">
                                        <h6 className="input-label"> </h6>
                                        <div className="input-field ref_input">
                                          <input
                                            type="text"
                                            value={
                                              owner.beneficialPoliticalSurName
                                            }
                                            onChange={(e) =>
                                              handleBeneficialChange(
                                                index,
                                                "beneficialPoliticalSurName",
                                                e.target.value
                                              )
                                            }
                                            className=""
                                            placeholder={t(
                                              "enter-surname-kyb5"
                                            )}
                                          />
                                        </div>
                                        {beneficialerrors[
                                          `politicalSurName_${index}`
                                        ] && (
                                          <p className="errorcss">
                                            {
                                              beneficialerrors[
                                                `politicalSurName_${index}`
                                              ]
                                            }
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="access_act_text">
                                    <p className="holder_content">
                                      {t(
                                        "are-you-relative-associate-any-person-who-currently-holds"
                                      )}
                                    </p>
                                    <div className="access_act_text_btn">
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "isrelative",
                                            "yes"
                                          )
                                        }
                                        className={
                                          owner.isrelative === "yes"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("yes")}
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleBeneficialChange(
                                            index,
                                            "isrelative",
                                            "no"
                                          )
                                        }
                                        className={
                                          owner.isrelative === "no"
                                            ? "yes_button"
                                            : "no_button"
                                        }
                                      >
                                        {t("no")}
                                      </button>
                                    </div>
                                  </div>
                                  {owner.isrelative === "yes" ? (
                                    <div className="input-groups fiat_deposit_name_details">
                                      <div className="fiat_deposit_detail">
                                        <h6 className="input-label clr-neutral">
                                          {t("if-yes-specify-name-surname")}
                                        </h6>
                                        <div className="input-field ref_input">
                                          <input
                                            type="disable"
                                            value={
                                              owner.beneficialrelativeFirstName
                                            }
                                            onChange={(e) =>
                                              handleBeneficialChange(
                                                index,
                                                "beneficialrelativeFirstName",
                                                e.target.value
                                              )
                                            }
                                            className=""
                                            placeholder={t("enter-fname-kyb5")}
                                          />
                                        </div>
                                        {beneficialerrors[
                                          `relativeFirstName_${index}`
                                        ] && (
                                          <p className="errorcss">
                                            {
                                              beneficialerrors[
                                                `relativeFirstName_${index}`
                                              ]
                                            }
                                          </p>
                                        )}
                                      </div>

                                      <div className="fiat_deposit_detail">
                                        <h6 className="input-label"> </h6>
                                        <div className="input-field ref_input">
                                          <input
                                            type="disable"
                                            value={
                                              owner.beneficialrelativeSurName
                                            }
                                            onChange={(e) =>
                                              handleBeneficialChange(
                                                index,
                                                "beneficialrelativeSurName",
                                                e.target.value
                                              )
                                            }
                                            className=""
                                            placeholder={t(
                                              "enter-surname-kyb5"
                                            )}
                                          />
                                        </div>
                                        {beneficialerrors[
                                          `relativeSurName_${index}`
                                        ] && (
                                          <p className="errorcss">
                                            {
                                              beneficialerrors[
                                                `relativeSurName_${index}`
                                              ]
                                            }
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="access_act_text">
                                    <h3>{t("identity-document")}</h3>
                                  </div>

                                  <div className="driver_license_content">
                                    <input
                                      id="check-comp"
                                      type="checkbox"
                                      className={
                                        beneficialidroofref.current == ""
                                          ? "checkbox-round"
                                          : "checkbox-round-checked"
                                      }
                                    />
                                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                                      <Select
                                        options={documentOptions}
                                        value={documentOptions.find(
                                          (option) =>
                                            option.value ===
                                            owner.beneficialidentityDocument
                                        )}
                                        onChange={(option) =>
                                          handleBeneficialChange(
                                            index,
                                            "beneficialidentityDocument",
                                            option.value
                                          )
                                        }
                                        className=""
                                        styles={colourStyles}
                                      />
                                    </div>
                                  </div>

                                  {owner.beneficialidentityDocument != "" && (
                                    <>
                                      <div className="driver_license_doc">
                                        <div className="upload">
                                          {beneficialidproofLoad == false ? (
                                            beneficialvalididproof == 0 ? (
                                              <>
                                                <div className="inner_frst_display">
                                                  <i class="ri-upload-2-line"></i>
                                                  <p>
                                                    {" "}
                                                    {t("click-to-upload-file")}
                                                  </p>
                                                </div>
                                              </>
                                            ) : beneficialvalididproof == 1 ? (
                                              <i class="bi bi-file-earmark-bar-graph"></i>
                                            ) : (
                                              <img
                                                src={
                                                  owner.beneficialidProof
                                                    ? owner.beneficialidProof
                                                    : beneficialidroofref.current
                                                }
                                                className="up_im_past"
                                                alt="Id Proof"
                                              />
                                            )
                                          ) : (
                                            <div className="inner_frst_display">
                                              <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                            </div>
                                          )}

                                          <input
                                            type="file"
                                            name="image"
                                            id={`beneficialidProof-${index}`}
                                            className="image_upload_kyc"
                                            onChange={(e) =>
                                              handleFileChange2(
                                                index,
                                                "beneficialidProof",
                                                e.target.files[0]
                                              )
                                            }
                                          />
                                          {beneficialidproofnameref.current ==
                                          "" ? (
                                            ""
                                          ) : (
                                            <div className="">
                                              <input
                                                className="proofs_name"
                                                disabled
                                                value={
                                                  beneficialidproofnameref.current
                                                }
                                              ></input>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {beneficialerrors[
                                    `beneficialidentityDocument_${index}`
                                  ] && (
                                    <p className="errorcss">
                                      {
                                        beneficialerrors[
                                          `beneficialidentityDocument_${index}`
                                        ]
                                      }
                                    </p>
                                  )}

                                  <div className="driver_license_doc">
                                    <div className="access_act_text">
                                      <h3>{t("proof-of-address")}</h3>
                                      <p className="clr-neutral">
                                        {t("Should-have-the-same-address")}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="driver_license_content">
                                    <input
                                      id="check-proof"
                                      type="checkbox"
                                      className={
                                        owner.beneficialaddressDocument == ""
                                          ? "checkbox-round"
                                          : "checkbox-round-checked"
                                      }
                                    />
                                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                                      <Select
                                        options={addressProofOptions}
                                        value={addressProofOptions.find(
                                          (option) =>
                                            option.value ===
                                            owner.beneficialaddressDocument
                                        )}
                                        onChange={(option) =>
                                          handleBeneficialChange(
                                            index,
                                            "beneficialaddressDocument",
                                            option.value
                                          )
                                        }
                                        styles={colourStyles}
                                      />
                                    </div>
                                  </div>

                                  {/* {owner.beneficialaddressDocument !="" &&( */}
                                  <>
                                    <div className="driver_license_doc">
                                      <div className="upload">
                                        {beneficialaddressProofLoad ===
                                        false ? (
                                          beneficialvalidaddressProof === 0 ? (
                                            <div className="inner_frst_display">
                                              <i className="ri-upload-2-line"></i>
                                              <p>
                                                {" "}
                                                {t("click-to-upload-file")}
                                              </p>
                                            </div>
                                          ) : beneficialvalidaddressProof ===
                                            1 ? (
                                            <i className="bi bi-file-earmark-bar-graph"></i>
                                          ) : (
                                            <img
                                              src={
                                                owner.beneficialaddressProof
                                                  ? owner.beneficialaddressProof
                                                  : beneficialidroofref.current
                                              }
                                              className="up_im_past"
                                              alt="Beneficial Address "
                                            />
                                          )
                                        ) : (
                                          <div className="inner_frst_display">
                                            <i className="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                          </div>
                                        )}
                                        <input
                                          type="file"
                                          id={`beneficialaddressProof-${index}`}
                                          onChange={(e) =>
                                            handleFileChange2(
                                              index,
                                              "beneficialaddressProof",
                                              e.target.files[0]
                                            )
                                          }
                                          className="image_upload_kyc"
                                        />
                                        {beneficialaddressproofnameref.current ===
                                        "" ? (
                                          ""
                                        ) : (
                                          <div>
                                            <input
                                              className="proofs_name"
                                              disabled
                                              value={
                                                beneficialaddressproofnameref.current
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                  {/* )} */}
                                  {beneficialerrors[
                                    `beneficialaddressDocument_${index}`
                                  ] && (
                                    <p className="errorcss">
                                      {
                                        beneficialerrors[
                                          `beneficialaddressDocument_${index}`
                                        ]
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            );
                          })}

                          {parentCompanies.map((parent, index) => (
                            <div key={index}>
                              <div className="delete_icon">
                                <p>
                                  {t("parent-company")}
                                  {index + 1}
                                </p>
                                <div
                                  className="delete_text mt-0"
                                  onClick={() =>
                                    handleRemoveParentCompany(index)
                                  }
                                >
                                  <i className="ri-delete-bin-6-line"></i>
                                </div>
                              </div>
                              <div className="beneficial_ownership">
                                <div className="col-lg-12">
                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("registration-type")}
                                      </h6>
                                      <div className="custom-select-stake kyc-verify my-2 w-100">
                                        <div className="input-groups select-wrapper ">
                                          <Select
                                            options={registrationOptions}
                                            value={registrationOptions.find(
                                              (option) =>
                                                option.value ===
                                                parent.parentalregistrationType
                                            )}
                                            onChange={(option) =>
                                              handleInputChange(
                                                index,
                                                "parentalregistrationType",
                                                option.value
                                              )
                                            }
                                            placeholder={t("select-type")}
                                            styles={colourStyles}
                                            className="reg-select-dropdown "
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="input-field ref_input upload_icon kyc_country_dropdown">
                                      </div> */}
                                      {errors[
                                        `parentalregistrationType_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            errors[
                                              `parentalregistrationType_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("registration-number")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="text"
                                          value={
                                            parent.parentalregistrationNumber
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "parentalregistrationNumber",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t(
                                            "regitstration-number-placeholder"
                                          )}
                                        />
                                      </div>
                                      {errors[
                                        `parentalregistrationNumber_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            errors[
                                              `parentalregistrationNumber_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("date-of-formation")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="date"
                                          value={parent.parentaldateOfFormation}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "parentaldateOfFormation",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t(
                                            "enter-date-of-formation"
                                          )}
                                        />
                                      </div>
                                      {errors[
                                        `parentaldateOfFormation_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            errors[
                                              `parentaldateOfFormation_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="fiat_deposit_detail country_kyp_dropdown">
                                    <h6 className="input-label">
                                      {t("country-formation")}
                                    </h6>
                                    {/* <div className="input-field ref_input kyc_country_dropdown">
                                    </div> */}
                                    <div className="custom-select-stake kyc-verify my-2 w-100">
                                      <div className="input-groups select-wrapper ">
                                        <Select
                                          options={options}
                                          value={options.find(
                                            (option) =>
                                              option.value ===
                                              parent.parentalcountryOfFormation
                                          )}
                                          onChange={(option) =>
                                            handleInputChange(
                                              index,
                                              "parentalcountryOfFormation",
                                              option.value
                                            )
                                          }
                                          placeholder={t("select-country")}
                                          styles={colourStyles}
                                          className="reg-select-dropdown "
                                        />
                                      </div>
                                    </div>

                                    {errors[
                                      `parentalcountryOfFormation_${index}`
                                    ] && (
                                      <p className="errorcss">
                                        {
                                          errors[
                                            `parentalcountryOfFormation_${index}`
                                          ]
                                        }
                                      </p>
                                    )}
                                  </div>

                                  <div className="input-groups fiat_deposit_name_details">
                                    <div className="fiat_deposit_detail city_content">
                                      <h6 className="input-label">
                                        {t("ownership-share-%")}
                                      </h6>
                                      <div className="input-field ref_input">
                                        <input
                                          type="number"
                                          value={parent.parentalownershipShare}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "parentalownershipShare",
                                              e.target.value
                                            )
                                          }
                                          placeholder={t(
                                            "enter-ownership-share"
                                          )}
                                        />
                                      </div>
                                      {errors[
                                        `parentalownershipShare_${index}`
                                      ] && (
                                        <p className="errorcss">
                                          {
                                            errors[
                                              `parentalownershipShare_${index}`
                                            ]
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor={`parentalcorporateFormation-${index}`}
                                      className="check-label"
                                    >
                                      {t("corporate-formation")}
                                    </label>

                                    <div className="driver_license_content">
                                      <div className="driver_license_doc">
                                        <div className="upload">
                                          <input
                                            type="file"
                                            id={`parentalcorporateFormation-${index}`}
                                            name="image"
                                            className="image_upload_kyc"
                                            onChange={(e) =>
                                              handleFileChange(
                                                index,
                                                "parentalcorporateFormationProof",
                                                e.target.files[0]
                                              )
                                            }
                                          />

                                          {corporateFormationproofLoad ==
                                          false ? (
                                            validcorporateFormationproof ==
                                            0 ? (
                                              <>
                                                <div className="inner_frst_display">
                                                  <i className="ri-upload-2-line"></i>
                                                  <p>
                                                    {" "}
                                                    {t("click-to-upload-file")}
                                                  </p>
                                                </div>
                                              </>
                                            ) : validcorporateFormationproof ==
                                              1 ? (
                                              <i className="bi bi-file-earmark-bar-graph"></i>
                                            ) : (
                                              <img
                                                // src={corporateFormationproofref.curren t}
                                                src={
                                                  corporateFormationproofref.current
                                                    ? corporateFormationproofref.current
                                                    : parent.parentalcorporateFormationProof
                                                }
                                                className="up_im_past"
                                                alt="Corporate Formation Proof"
                                              />
                                            )
                                          ) : (
                                            <div className="inner_frst_display">
                                              <i className="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                            </div>
                                          )}

                                          {corporateFormationproofnameref.current ==
                                          "" ? (
                                            ""
                                          ) : (
                                            <div className="">
                                              <input
                                                className="proofs_name"
                                                disabled
                                                value={
                                                  corporateFormationproofnameref.current
                                                }
                                              ></input>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {errors[
                                      `parentalcorporateFormationProof_${index}`
                                    ] && (
                                      <p className="errorcss">
                                        {
                                          errors[
                                            `parentalcorporateFormationProof_${index}`
                                          ]
                                        }
                                      </p>
                                    )}
                                  </div>
                                  <div>
                                    <label
                                      htmlFor={`parentalcompanyShareRegistry-${index}`}
                                      className="check-label"
                                    >
                                      {t("company-share-registry")}
                                    </label>
                                    <div className="driver_license_content">
                                      <div className="driver_license_doc">
                                        <div className="upload">
                                          <input
                                            className="image_upload_kyc"
                                            type="file"
                                            id={`parentalcompanyShareRegistry-${index}`}
                                            onChange={(e) =>
                                              handleFileChange(
                                                index,
                                                "parentalcompanyShareRegistry",
                                                e.target.files[0]
                                              )
                                            }
                                          />

                                          {companyproofLoad == false ? (
                                            validcompanyproof == 0 ? (
                                              <>
                                                <div className="inner_frst_display">
                                                  <i class="ri-upload-2-line"></i>
                                                  <p>
                                                    {" "}
                                                    {t("click-to-upload-file")}
                                                  </p>
                                                </div>
                                              </>
                                            ) : validcompanyproof == 1 ? (
                                              <i class="bi bi-file-earmark-bar-graph"></i>
                                            ) : (
                                              <img
                                                src={
                                                  companyproofref.current
                                                    ? companyproofref.current
                                                    : parent.parentalcompanyShareRegistry
                                                }
                                                className="up_im_past"
                                                alt="National Id Front"
                                              />
                                            )
                                          ) : (
                                            <div className="inner_frst_display">
                                              <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                            </div>
                                          )}

                                          {companyproofnameref.current == "" ? (
                                            ""
                                          ) : (
                                            <div className="">
                                              <input
                                                className="proofs_name"
                                                disabled
                                                value={
                                                  companyproofnameref.current
                                                }
                                              ></input>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {errors[
                                      `parentalcompanyShareRegistry_${index}`
                                    ] && (
                                      <p className="errorcss">
                                        {
                                          errors[
                                            `parentalcompanyShareRegistry_${index}`
                                          ]
                                        }
                                      </p>
                                    )}
                                  </div>

                                  <div>
                                    <label
                                      htmlFor={`parentaladdressProof-${index}`}
                                      className="check-label"
                                    >
                                      {t("proof-business-operating-address")}
                                    </label>
                                    <div className="driver_license_content">
                                      <input
                                        id={`check-proof-${index}`}
                                        type="checkbox"
                                        className={
                                          parentaladdressProofref.current === ""
                                            ? "checkbox-round"
                                            : "checkbox-round-checked"
                                        }
                                        disabled
                                      />

                                      {/* Dropdown to select the type of address proof */}
                                      <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                                        <Select
                                          options={addressProofOptions}
                                          onChange={
                                            handleparentAddressProofChange
                                          }
                                          placeholder={t(
                                            "select-operating-address"
                                          )}
                                          value={addressProofOptions.find(
                                            (option) =>
                                              option.value ===
                                              parentaladdressProofref.current
                                          )}
                                          styles={colourStyles}
                                        />
                                      </div>
                                    </div>
                                    {errors[
                                      `parentaladdressProof_${index}`
                                    ] && (
                                      <p className="errorcss">
                                        {
                                          errors[
                                            `parentaladdressProof_${index}`
                                          ]
                                        }
                                      </p>
                                    )}
                                    <div className="driver_license_content">
                                      {/* File upload section */}
                                      <div className="driver_license_doc">
                                        <div className="upload">
                                          <input
                                            type="file"
                                            id={`parentaladdressProof-${index}`}
                                            onChange={(e) =>
                                              handleFileChange(
                                                index,
                                                "parentaladdressProof",
                                                e.target.files[0]
                                              )
                                            }
                                            className="image_upload_kyc"
                                          />

                                          {/* Display uploaded file name or progress */}
                                          {parentaladdressProofLoad ===
                                          false ? (
                                            parentalvalidaddressProof === 0 ? (
                                              <div className="inner_frst_display">
                                                <i className="ri-upload-2-line"></i>
                                                <p>
                                                  {" "}
                                                  {t("click-to-upload-file")}
                                                </p>
                                              </div>
                                            ) : parentalvalidaddressProof ===
                                              1 ? (
                                              <i className="bi bi-file-earmark-bar-graph"></i>
                                            ) : (
                                              <img
                                                src={
                                                  parentaladdressProofref.current
                                                    ? parentaladdressProofref.current
                                                    : parent.parentaladdressProof
                                                }
                                                className="up_im_past"
                                                alt="National Id Front"
                                              />
                                            )
                                          ) : (
                                            <div className="inner_frst_display">
                                              <i className="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                            </div>
                                          )}

                                          {/* Display the name of the uploaded file */}
                                          {parentaladdressproofnameref.current ===
                                          "" ? (
                                            ""
                                          ) : (
                                            <div>
                                              <input
                                                className="proofs_name"
                                                disabled
                                                value={
                                                  parentaladdressproofnameref.current
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Organizational chart */}
                    <div class="accordion-item kyp-accor-header">
                      <h2
                        class="accordion-header kyp-accor-header"
                        id="headingThree"
                      >
                        <button
                          class="accordion-button collapsed kyp-accor-header"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {t("organizational-chart")}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          {/* {organizalChart != "" && ( */}
                          <>
                            <div className="driver_license_doc">
                              <div className="upload">
                                {organizeidproofLoad == false ? (
                                  organizevalididproof == 0 ? (
                                    <>
                                      <div className="inner_frst_display">
                                        <i class="ri-upload-2-line"></i>
                                        <p> {t("click-to-upload-file")}</p>
                                      </div>
                                    </>
                                  ) : organizevalididproof == 1 ? (
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  ) : (
                                    <img
                                      src={organizeidroofref.current}
                                      className="up_im_past"
                                      alt="National Id Front"
                                    />
                                  )
                                ) : (
                                  <div className="inner_frst_display">
                                    <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                                  </div>
                                )}

                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    organizeimageUpload(
                                      "organizechart",
                                      e.target.files[0]
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                          {/* // )} */}

                          <>
                            {organizalChartvallidateref.current && (
                              <p className="errorcss">
                                {organizalChartErrMsgref.current}
                              </p>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Submit my-4" onClick={handleSubmit}>
                    <Link>
                      <button>{t("next")}</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
