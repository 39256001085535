import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import Markettable from "./Markettable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";

const Market = () => {
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {

    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }


    gethomeCurrency();
  }, []);

  const navtradepage = async (symbol) => {
    window.location.href = `trade/${symbol}_USDT`;
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main h-100">
          <div className="container">
            <div className="row">
            {loginCheck == true ? 
              <div className="col-lg-2">
                <Side_bar />
              </div>
                :""}

              <div className= {loginCheck == true ? "col-lg-10":"col-lg-12"}>
                <section className="asset_section">
                  <div className="row">
                    <div className="buy_head">
                      <div className="buy-rewards">
                        <span className="reward-title">{t("market")}</span>
                        {/* <div className="stake-search-container">
                        <input
                          type="text"
                          placeholder="Search"
                          className="stake-input"
                        />
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div> */}
                      </div>
                      <ul className="history-lists">
                        <Link className="history-links active">
                          {t("spot")}
                        </Link>
                      </ul>

                      {/* <Markettable/> */}

                      <div className="table-responsive table-cont dash_table_content">
                        <table className="table ">
                          <thead>
                            <tr className="stake-head font-satoshi">
                              <th>{t("name")}</th>
                              <th className="opt-nowrap pad-left-23 px-0">
                                {t("price")} (USD)
                              </th>
                              <th className="table_action tab-pad-r-10">
                                {t("trade")}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currencylistData && currencylistData.length > 0 ? (
                              currencylistData.map((obj, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="table-flex">
                                      <img src={obj.Currency_image} alt="" />
                                      <div className="table-opt-name">
                                        <h4 className="opt-name font-satoshi font_14">
                                          {obj.currencyName}
                                        </h4>
                                        <h3 className="opt-sub font-satoshi font_14">
                                          {obj.currencySymbol}
                                        </h3>
                                      </div>
                                    </td>

                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 tab-pad-l-10">
                                      {obj.marketprice <= 0 ? (
                                        <td className="text-danger">
                                          {obj.currencySymbol == "SHIB" ? (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(8)}
                                            </span>
                                          ) : (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(2)}
                                            </span>
                                          )}
                                        </td>
                                      ) : (
                                        <td className="text-success">
                                          {obj.currencySymbol == "SHIB" ? (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(8)}
                                            </span>
                                          ) : (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(2)}
                                            </span>
                                          )}
                                        </td>
                                      )}
                                    </td>

                                    <td className="opt-btn-flex table_action pad-left-23">
                                      <div className="deposit_top_button">
                                        <button
                                          className="action_btn "
                                          onClick={() =>
                                            navtradepage(obj.currencySymbol)
                                          }
                                        >
                                          Trade
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={3} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.png")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      {t("no-records-found")}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Market;
