import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { env } from "../core/service/envconfig";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");

  const [selectPerson, setSelectPerson] = useState("yes");
  const [selectAccess, setSelectAccess] = useState("yes");
  const [selectAssociate, setSelectAssociate, selectAssociateref] =
    useState("yes");
  const [selectLicense, setSelectLicense] = useState(false);
  const [credit, setCredit] = useState(false);

  const { t } = useTranslation();

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  useEffect(() => {
    getStep();
  }, [0]);
  const [currentStep, setCurrentStep, currentStepref] = useState("Step-1");

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kycData = resp.data;
        formValue.authorisedaccessorFirstName = kycData.firstName;
        formValue.authorisedaccessorSurName = kycData.surname;
        formValue.authorisedaccessorDOB = kycData.dateOfBirth;
        formValue.authorisedaccessoraddress = kycData.address;
        formValue.authorisedaccessorunit = kycData.unit;
        formValue.authorisedaccessorcity = kycData.city;
        formValue.authorisedaccessorPostalCode = kycData.postalCode;
        formValue.authorisedaccessorphoneNumber = kycData.phoneNumber;
        formValue.authorisedaccessorcountry = kycData.country;
        formValue.authorisedaccessorNationality = kycData.nationality;
        formValue.authorisedaccessoraccountacess = kycData.personAccess;
        formValue.authorisedaccessorPoliticallyExposed =
          kycData.politicalPerson;
        formValue.authorisedaccessorAccountHolderrelative =
          kycData.publicPosition;
        formValue.authorisedaccessorAccountHolderrelativefirstName =
          kycData.associateFirstname;
        formValue.authorisedaccessorAccountHolderrelativeSurName =
          kycData.associateSurname;
        formValue.authorisedaccessorAccountHolderrelativerelationship =
          kycData.relation;
        formValue.authorisedaccessoidentityDocument = kycData.identityDocument;
        formValue.authorisedaccessoaddressProof = kycData.addressProof;
        if (kycData.authorisedaccessoraddressProofurl != "") {
          setidproofLoad(false);
          setvalididproof(2);
          setaddressProof(kycData.authorisedaccessoraddressProofurl);
        }
        if (kycData.authorisedaccessoridentityDocumenturl != "") {
          setaddressProofLoad(false);
          setvalidaddressProof(2);
          setidproof(kycData.authorisedaccessoridentityDocumenturl);
        }
      }
    } catch (err) {}
  };

  const handleResend = async () => {
    setCounter(60);
    setIsResendVisible(false);
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  // access to the account
  const handleYesAccess = () => {
    setSelectPerson("yes");
  };
  const handleNoAccess = () => {
    setSelectPerson("no");
  };

  // account holder
  const accountYes = () => {
    setSelectAccess("yes");
  };
  const accountNo = () => {
    setSelectAccess("no");
  };

  // relative or associate
  const handleYesAssociate = () => {
    setSelectAssociate("yes");
  };
  const handleNoAssociate = () => {
    setSelectAssociate("no");
  };

  const handleLicense = () => {
    setSelectLicense(!selectLicense);
  };

  const handleCredit = () => {
    setCredit(!credit);
  };

  ///AUTHER -JOTHI START

  const initialFormValue = {
    authorisedaccessorcheck: "",
    authorisedaccessorFirstName: "",
    authorisedaccessorSurName: "",
    authorisedaccessorDOB: "",
    authorisedaccessoraddress: "",
    authorisedaccessorunit: "",
    authorisedaccessorcity: "",
    authorisedaccessorphoneNumber: "",
    authorisedaccessorPostalCode: "",
    authorisedaccessorcountry: "",
    authorisedaccessorNationality: "",
    authorisedaccessoraccountacess: "",
    authorisedaccessorPoliticallyExposed: "",
    authorisedaccessorAccountHolderrelative: "",
    authorisedaccessorAccountHolderrelativefirstName: "",
    authorisedaccessorAccountHolderrelativeSurName: "",
    authorisedaccessorAccountHolderrelativerelationship: "",
    authorisedaccessoidentityDocument: "",
    authorisedaccessoaddressProof: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationnErr, setvalidationnErr] = useState("");
  const [
    authorisedaccessorFirstNameErr,
    setauthorisedaccessorFirstNameErr,
    authorisedaccessorFirstNameErrref,
  ] = useState(false);
  const [
    authorisedaccessorSurNameErr,
    setauthorisedaccessorSurNameErr,
    authorisedaccessorSurNameErrref,
  ] = useState(false);
  const [
    authorisedaccessorDOBErr,
    setauthorisedaccessorDOBErr,
    authorisedaccessorDOBErrref,
  ] = useState(false);
  const [
    authorisedaccessoraddressErr,
    setauthorisedaccessoraddressErr,
    authorisedaccessoraddressErrref,
  ] = useState(false);
  const [
    authorisedaccessorcityErr,
    setauthorisedaccessorcityErr,
    authorisedaccessorcityErrref,
  ] = useState(false);
  const [
    authorisedaccessorPostalCodeErr,
    setauthorisedaccessorPostalCodeErr,
    authorisedaccessorPostalCodeErrref,
  ] = useState(false);
  const [
    authorisedaccessorphoneNumberErr,
    setauthorisedaccessorphoneNumberErr,
    authorisedaccessorphoneNumberErrref,
  ] = useState(false);
  const [
    authorisedaccessorcountryErr,
    setauthorisedaccessorcountryErr,
    authorisedaccessorcountryErrref,
  ] = useState(false);
  const [
    authorisedaccessorNationalityErr,
    setauthorisedaccessorNationalityErr,
    authorisedaccessorNationalityErrref,
  ] = useState(false);
  const [
    authorisedaccessoraccountacessErr,
    setauthorisedaccessoraccountacessErr,
    authorisedaccessoraccountacessErrref,
  ] = useState(false);
  const [
    authorisedaccessorPoliticallyExposedErr,
    setauthorisedaccessorPoliticallyExposedErr,
    authorisedaccessorPoliticallyExposedErrref,
  ] = useState(false);
  const [
    authorisedaccessorAccountHolderrelativeErr,
    setauthorisedaccessorAccountHolderrelativeErr,
    authorisedaccessorAccountHolderrelativeErrref,
  ] = useState(false);
  const [
    authorisedaccessorAccountHolderrelativefirstNameErr,
    setauthorisedaccessorAccountHolderrelativefirstNameErr,
    authorisedaccessorAccountHolderrelativefirstNameErrref,
  ] = useState(false);
  const [
    authorisedaccessorAccountHolderrelativeSurNameErr,
    setauthorisedaccessorAccountHolderrelativeSurNameErr,
    authorisedaccessorAccountHolderrelativeSurNameErrref,
  ] = useState(false);
  const [
    authorisedaccessorAccountHolderrelativerelationshipErr,
    setauthorisedaccessorAccountHolderrelativerelationshipErr,
    authorisedaccessorAccountHolderrelativerelationshipErrref,
  ] = useState(false);
  const [
    authorisedaccessoidentityDocumentErr,
    setauthorisedaccessoidentityDocumentErr,
    authorisedaccessoidentityDocumentErrref,
  ] = useState(false);
  const [
    authorisedaccessoaddressProoftErr,
    setauthorisedaccessoaddressProoftErr,
    authorisedaccessoaddressProoftErrref,
  ] = useState(false);
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);
  const [idproof, setidproof, idproofref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofup, setaddressProof, addressProofref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const {
    authorisedaccessorcheck,
    authorisedaccessorFirstName,
    authorisedaccessorSurName,
    authorisedaccessorDOB,
    authorisedaccessoraddress,
    authorisedaccessorunit,
    authorisedaccessorcity,
    authorisedaccessorPostalCode,
    authorisedaccessorphoneNumber,
    authorisedaccessorcountry,
    authorisedaccessorNationality,
    authorisedaccessoraccountacess,
    authorisedaccessorPoliticallyExposed,
    authorisedaccessorAccountHolderrelative,
    authorisedaccessorAccountHolderrelativefirstName,
    authorisedaccessorAccountHolderrelativeSurName,
    authorisedaccessorAccountHolderrelativerelationship,
    authorisedaccessoidentityDocument,
    authorisedaccessoaddressProof,
  } = formValue;

  const relationOptions = [
    { value: "Spouse", label: t("spouse") },
    { value: "Child", label: t("child") },
    { value: "Parent", label: t("parent") },
    { value: "Associate", label: t("associate") },
  ];
  const documentOptions = [
    { value: "Passport", label: t("passport") },
    { value: "Driver's license", label: t("driver-license") },
    { value: "ID Card", label: t("id-card") },
  ];
  const addressProofOptions = [
    { value: "Utility Bill", label: t("utility-bill") },
    { value: "Bank Statement", label: t("bank-statement") },
    { value: "Credit Card Statement", label: t("credit-card-statement") },
    {
      value: "Proof of Residency Certificate",
      label: t("proof-of-residency-certificate"),
    },
  ];

  const validate = async (values) => {
    try {
      let errors = {};

      if (!values.authorisedaccessorFirstName) {
        errors.authorisedaccessorFirstName = t("first-name-required");
        setauthorisedaccessorFirstNameErr(true);
      } else if (!/^[a-zA-Z]/.test(values.authorisedaccessorFirstName)) {
        errors.authorisedaccessorFirstName = t("firstname-must-start-letter");
        setauthorisedaccessorFirstNameErr(true);
      } else if (
        values.authorisedaccessorFirstName.length < 3 ||
        values.authorisedaccessorFirstName.length > 25
      ) {
        errors.authorisedaccessorFirstName = t("firstname-must-3-25-char");
        setauthorisedaccessorFirstNameErr(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(values.authorisedaccessorFirstName)) {
        errors.authorisedaccessorFirstName = t(
          "fname-only-contain-letter-num-underscore"
        );
        setauthorisedaccessorFirstNameErr(true);
      } else {
        setauthorisedaccessorFirstNameErr(false);
      }
      if (!values.authorisedaccessorSurName) {
        errors.authorisedaccessorSurName = t("first-name-required");
        setauthorisedaccessorSurNameErr(true);
      } else if (!/^[a-zA-Z]/.test(values.authorisedaccessorSurName)) {
        errors.authorisedaccessorSurName = t("firstname-must-start-letter");
        setauthorisedaccessorSurNameErr(true);
      } else if (
        values.authorisedaccessorSurName.length < 3 ||
        values.authorisedaccessorSurName.length > 25
      ) {
        errors.authorisedaccessorSurName = t("firstname-must-3-25-char");
        setauthorisedaccessorSurNameErr(true);
      } else if (!/^[a-zA-Z0-9_]+$/.test(values.authorisedaccessorSurName)) {
        errors.authorisedaccessorSurName = t(
          "fname-only-contain-letter-num-underscore"
        );
        setauthorisedaccessorSurNameErr(true);
      } else {
        setauthorisedaccessorSurNameErr(false);
      }

      if (values.authorisedaccessorDOB == "") {
        setauthorisedaccessorDOBErr(true);
        errors.authorisedaccessorDOB = t("date-of-birth-required");
      } else {
        setauthorisedaccessorDOBErr(false);
      }

      if (values.authorisedaccessoraddress == "") {
        setauthorisedaccessoraddressErr(true);
        errors.authorisedaccessoraddress = t("address-required");
      } else {
        setauthorisedaccessoraddressErr(false);
      }

      if (values.authorisedaccessorcity == "") {
        setauthorisedaccessorcityErr(true);
        errors.authorisedaccessorcity = t("city-required");
      } else {
        setauthorisedaccessorcityErr(false);
      }

      if (values.authorisedaccessorPostalCode == "") {
        setauthorisedaccessorPostalCodeErr(true);
        errors.authorisedaccessorPostalCode = t("zip/postal-required");
      } else {
        setauthorisedaccessorPostalCodeErr(false);
      }

      if (values.authorisedaccessorphoneNumber == "") {
        setauthorisedaccessorphoneNumberErr(true);
        errors.authorisedaccessorphoneNumber = t("phone-number-required");
      } else if (values.authorisedaccessorphoneNumber.length < 5) {
        errors.authorisedaccessorphoneNumber = t(
          "phone-num-should-not-below-5"
        );
        setauthorisedaccessorphoneNumberErr(true);
      } else {
        setauthorisedaccessorphoneNumberErr(false);
      }

      if (values.authorisedaccessorcountry == "") {
        setauthorisedaccessorcountryErr(true);
        errors.authorisedaccessorcountry = t("country-required");
      } else {
        setauthorisedaccessorcountryErr(false);
      }

      if (values.authorisedaccessorNationality == "") {
        setauthorisedaccessorNationalityErr(true);
        errors.authorisedaccessorNationality = t("nationality-required");
      } else {
        setauthorisedaccessorNationalityErr(false);
      }

      if (selectAssociateref.current === "yes") {
        if (values.authorisedaccessorAccountHolderrelativefirstName == "") {
          setauthorisedaccessorAccountHolderrelativefirstNameErr(true);
          errors.authorisedaccessorAccountHolderrelativefirstName = t(
            "associate-fname-required"
          );
        } else {
          setauthorisedaccessorAccountHolderrelativefirstNameErr(false);
        }
        if (values.authorisedaccessorAccountHolderrelativeSurName == "") {
          setauthorisedaccessorAccountHolderrelativeSurNameErr(true);
          errors.authorisedaccessorAccountHolderrelativeSurName = t(
            "associate-surname-required"
          );
        } else {
          setauthorisedaccessorAccountHolderrelativeSurNameErr(false);
        }
        if (values.authorisedaccessorAccountHolderrelativerelationship == "") {
          setauthorisedaccessorAccountHolderrelativerelationshipErr(true);
          errors.authorisedaccessorAccountHolderrelativerelationship = t(
            "associate-relation-required"
          );
        } else {
          setauthorisedaccessorAccountHolderrelativerelationshipErr(false);
        }
      }

      if (values.authorisedaccessoidentityDocument == "") {
        setauthorisedaccessoidentityDocumentErr(true);
        errors.authorisedaccessoidentityDocument = t(
          "please-select-one-identity-doc"
        );
      } else {
        setauthorisedaccessoidentityDocumentErr(false);
      }
      if (values.authorisedaccessoaddressProof == "") {
        setauthorisedaccessoaddressProoftErr(true);
        errors.authorisedaccessoaddressProof = t(
          "please-select-one-address-proof"
        );
      } else {
        setauthorisedaccessoaddressProoftErr(false);
      }

      setvalidationnErr(errors);
      return errors;
    } catch (err) {}
  };

  const handleCountryChange = (selectedCountry) => {
    const correspondingNationality = options.find(
      (option) => option.value === selectedCountry.value
    );

    const updatedFormValue = {
      ...formValue,
      authorisedaccessorcountry: selectedCountry.value,
      authorisedaccessorNationality: correspondingNationality
        ? correspondingNationality.value
        : "",
    };

    setFormValue(updatedFormValue);
    // validate(updatedFormValue); // Assuming validate is a function to validate the form
  };

  const handleNationalityChange = (selectedNationality) => {
    const updatedFormValue = {
      ...formValue,
      authorisedaccessorNationality: selectedNationality.value,
    };

    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const handleRelationChange = (selectedRelation) => {
    const updatedFormValue = {
      ...formValue,
      authorisedaccessorAccountHolderrelative: selectedRelation
        ? selectedRelation.value
        : "",
    };

    setFormValue(updatedFormValue);
    // validate(updatedFormValue); // Assuming validate is a function to validate the form
  };

  const handleDocumentChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      authorisedaccessoidentityDocument: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };
  const handleAddressProofChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      authorisedaccessoaddressProof: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    // validate(updatedFormValue);
  };

  const handlePhoneChange = (value) => {
    setFormValue({ ...formValue, authorisedaccessorphoneNumber: value });
    // validate({ ...formValue, authorisedaccessorphoneNumber: value });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    if (name === "authorisedaccessorphoneNumber") {
      sanitizedValue = sanitizedValue.replace(/\D/g, "").slice(0, 10);
    }
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    // validate(formData);
  };

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(t("file-does-not-support"));
      } else if (fileSize > 10000000) {
        toast.error(t("please-upload-file-smaller-than-1mb"));
      } else {
        type == "identityDocument"
          ? setidproofLoad(true)
          : setaddressProofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "identityDocument") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }
              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);
              setaddressProof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(t("please-try-again-later"));
          });
      }
    } catch (error) {
      toast.error(t("please-try-again-later"));
    }
  };

  const submit = async () => {
    try {
      let errros = validate(formValue);
      if (
        authorisedaccessorFirstNameErrref.current === false &&
        authorisedaccessorSurNameErrref.current === false &&
        authorisedaccessorDOBErrref.current === false &&
        authorisedaccessoraddressErrref.current === false &&
        authorisedaccessorcityErrref.current === false &&
        authorisedaccessorPostalCodeErrref.current === false &&
        authorisedaccessorphoneNumberErrref.current === false &&
        authorisedaccessorcountryErrref.current === false &&
        authorisedaccessorNationalityErrref.current === false &&
        authorisedaccessorAccountHolderrelativefirstNameErrref.current ===
          false &&
        authorisedaccessorAccountHolderrelativeSurNameErrref.current ===
          false &&
        authorisedaccessorAccountHolderrelativerelationshipErrref.current ===
          false &&
        authorisedaccessoidentityDocumentErrref.current === false &&
        authorisedaccessoaddressProoftErrref.current === false
      ) {
        formValue["verifiedSteps"] = "Step-5";
        formValue["authorisedaccessoridentityDocumenturl"] = idproofref.current;
        formValue["authorisedaccessoraddressProofurl"] =
          addressProofref.current;

        var data = {
          apiUrl: apiService.kycFifth,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        // setFormValue(initialFormValue);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/KycInformation6");
        } else {
          toast.error(resp.Message);
        }
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };
  const [isChecked, setIschecked] = useState(false);

  const handleTerms = async (event) => {
    setIschecked(event.target.checked);
    if (event.target.checked === true) {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      var kycData = resp.data;
      formValue.authorisedaccessorFirstName = kycData.firstName;
      formValue.authorisedaccessorSurName = kycData.surname;
      formValue.authorisedaccessorDOB = kycData.dateOfBirth;
      formValue.authorisedaccessoraddress = kycData.address;
      formValue.authorisedaccessorunit = kycData.unit;
      formValue.authorisedaccessorcity = kycData.city;
      formValue.authorisedaccessorPostalCode = kycData.postalCode;
      formValue.authorisedaccessorphoneNumber = kycData.phoneNumber;
      formValue.authorisedaccessorcountry = kycData.country;
      formValue.authorisedaccessorNationality = kycData.nationality;
      formValue.authorisedaccessoraccountacess = kycData.personAccess;
      formValue.authorisedaccessorPoliticallyExposed = kycData.politicalPerson;
      formValue.authorisedaccessorAccountHolderrelative =
        kycData.publicPosition;
      formValue.authorisedaccessorAccountHolderrelativefirstName =
        kycData.associateFirstname;
      formValue.authorisedaccessorAccountHolderrelativeSurName =
        kycData.associateSurname;
      formValue.authorisedaccessorAccountHolderrelativerelationship =
        kycData.relation;
      formValue.authorisedaccessoidentityDocument = kycData.identityDocument;
      formValue.authorisedaccessoaddressProof = kycData.addressProof;
      formValue["authorisedaccessoraddressProofurl"] = kycData.addressProof;
      formValue["authorisedaccessoridentityDocumenturl"] = kycData.addressProof;

      setidproofname(kycData.identityDocument);
      setidproof(kycData.identityDocumenturl);
      setvalididproof(2);
      setvalidaddressProof(2);
      setaddressProof(kycData.addressProofurl);
      setaddressproofname(kycData.addressProof);
    } else {
      formValue.authorisedaccessorFirstName = "";
      formValue.authorisedaccessorSurName = "";
      formValue.authorisedaccessorDOB = "";
      formValue.authorisedaccessoraddress = "";
      formValue.authorisedaccessorunit = "";
      formValue.authorisedaccessorcity = "";
      formValue.authorisedaccessorPostalCode = "";
      formValue.authorisedaccessorphoneNumber = "";
      formValue.authorisedaccessorcountry = "";
      formValue.authorisedaccessorNationality = "";
      formValue.authorisedaccessoraccountacess = "";
      formValue.authorisedaccessorPoliticallyExposed = "";
      formValue.authorisedaccessorAccountHolderrelative = "";
      formValue.authorisedaccessorAccountHolderrelativefirstName = "";
      formValue.authorisedaccessorAccountHolderrelativeSurName = "";
      formValue.authorisedaccessorAccountHolderrelativerelationship = "";
      formValue.authorisedaccessoidentityDocument = "";
      formValue.authorisedaccessoaddressProof = "";
      setidproofname("");
      setidproof("");
      setvalididproof(0);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KycInformation4">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">5</span>
                    {t("authorized-accessor-details")}
                  </h3>
                  <div className="input-checkboxs">
                    <ul>
                      <li className="checkbox-container">
                        <input
                          id="check-business"
                          type="checkbox"
                          className="regular_checkbox"
                          placeholder={t("select-your-status")}
                          value={isChecked}
                          name="check"
                          onChange={handleTerms}
                        />
                        <div className="custom-check"></div>
                        <label htmlFor="check-business" className="clr-primary">
                          {t("authorized-accessor-account")}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="holder_checkbox_content">
                    {t("if-box-above-ticked")}
                  </div>
                  <div className="opening_acc_title">
                    <h4>{t("person-opening-account")}</h4>
                    <i class="ri-arrow-up-s-line"></i>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("first-name")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="authorisedaccessorFirstName"
                          value={authorisedaccessorFirstName}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-your-name-here")}
                        />
                      </div>
                      {validationnErr &&
                        validationnErr.authorisedaccessorFirstName && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessorFirstName}
                          </p>
                        )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("surname")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="authorisedaccessorSurName"
                          value={authorisedaccessorSurName}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-your-surname-here")}
                        />
                      </div>
                      {validationnErr &&
                        validationnErr.authorisedaccessorSurName && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessorSurName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("date-of-birth")}</h6>
                    <div className="input-field ref_input">
                      <input
                        type="date"
                        name="authorisedaccessorDOB"
                        value={authorisedaccessorDOB}
                        onChange={handleChange}
                        className=""
                        placeholder="MM/DD/YYYY"
                      />
                    </div>
                    {validationnErr && validationnErr.authorisedaccessorDOB && (
                      <p className="errorcss">
                        {validationnErr.authorisedaccessorDOB}
                      </p>
                    )}
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("address")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="authorisedaccessoraddress"
                          value={authorisedaccessoraddress}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-date-of-formation")}
                        />
                      </div>
                      {validationnErr &&
                        validationnErr.authorisedaccessoraddress && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessoraddress}
                          </p>
                        )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("unit#(optional)")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="number"
                          name="authorisedaccessorunit"
                          value={authorisedaccessorunit}
                          onChange={handleChange}
                          className=""
                          placeholder="Unit #"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("city")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="authorisedaccessorcity"
                          value={authorisedaccessorcity}
                          onChange={handleChange}
                          className=""
                          placeholder="Spain"
                        />
                      </div>
                      {validationnErr &&
                        validationnErr.authorisedaccessorcity && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessorcity}
                          </p>
                        )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("phone-number")}</h6>
                      <PhoneInput
                        country={"us"}
                        value={authorisedaccessorphoneNumber}
                        onChange={handlePhoneChange}
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          className: "input-phone mb-0",
                        }}
                      />
                      {validationnErr &&
                        validationnErr.authorisedaccessorphoneNumber && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessorphoneNumber}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("zip-postal-code")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="authorisedaccessorPostalCode"
                          value={authorisedaccessorPostalCode}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-zip-postal-code")}
                        />
                      </div>
                      {validationnErr &&
                        validationnErr.authorisedaccessorPostalCode && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessorPostalCode}
                          </p>
                        )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("country")}</h6>

                      <div className="custom-select-stake kyc-verify my-2 w-100">
                        <div className="input-groups select-wrapper ">
                          <Select
                            options={options}
                            value={options.find(
                              (option) =>
                                option.value === authorisedaccessorphoneNumber
                            )}
                            onChange={handleCountryChange}
                            placeholder={t("select-country")}
                            styles={colourStyles}
                            className="reg-select-dropdown "
                          />
                        </div>
                      </div>
                      {validationnErr &&
                        validationnErr.authorisedaccessorphoneNumber && (
                          <p className="errorcss">
                            {validationnErr.authorisedaccessorphoneNumber}
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">{t("nationality")}</h6>
                    <div className="custom-select-stake my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={options}
                          value={options.find(
                            (option) =>
                              option.value === authorisedaccessorNationality
                          )}
                          onChange={handleNationalityChange}
                          placeholder={t("select-nationality")}
                          styles={colourStyles}
                          className="reg-select-dropdown"
                        />
                      </div>
                    </div>
                    {validationnErr &&
                      validationnErr.authorisedaccessorNationality && (
                        <p className="errorcss">
                          {validationnErr.authorisedaccessorNationality}
                        </p>
                      )}
                  </div>
                  <div className="access_act_text">
                    <h3>{t("will-this-person-access")}</h3>
                    <div className="access_act_text_btn">
                      <button
                        onClick={handleYesAccess}
                        className={
                          selectPerson === "yes" ? "yes_button" : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={handleNoAccess}
                        className={
                          selectPerson === "no" ? "yes_button" : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="access_act_text">
                    <h3> {t("is-the-account-holder")}</h3>
                    <p>{t("persons-who-hold")}</p>
                    <div className="access_act_text_btn">
                      <button
                        onClick={accountYes}
                        className={
                          selectAccess === "yes" ? "yes_button" : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={accountNo}
                        className={
                          selectAccess === "no" ? "yes_button" : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="access_act_text">
                    <p className="holder_content">
                      {t("is-the-account-holder-relative")}
                    </p>
                    <div className="access_act_text_btn">
                      <button
                        onClick={handleYesAssociate}
                        className={
                          selectAssociate === "yes" ? "yes_button" : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={handleNoAssociate}
                        className={
                          selectAssociate === "no" ? "yes_button" : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  {selectAssociateref.current === "yes" ? (
                    <>
                      <div className="input-groups fiat_deposit_name_details">
                        <div className="fiat_deposit_detail">
                          <h6 className="input-label">{t("first-name")}</h6>
                          <div className="input-field ref_input">
                            <input
                              type="text"
                              name="authorisedaccessorAccountHolderrelativefirstName"
                              value={
                                authorisedaccessorAccountHolderrelativefirstName
                              }
                              onChange={handleChange}
                              className=""
                              placeholder={t("enter-your-name-here")}
                            />
                          </div>
                          {selectAssociateref.current === "yes" &&
                            validationnErr &&
                            validationnErr.authorisedaccessorAccountHolderrelativefirstName && (
                              <p className="errorcss">
                                {
                                  validationnErr.authorisedaccessorAccountHolderrelativefirstName
                                }
                              </p>
                            )}
                        </div>
                        <div className="fiat_deposit_detail">
                          <h6 className="input-label">{t("surname")}</h6>
                          <div className="input-field ref_input">
                            <input
                              type="text"
                              name="authorisedaccessorAccountHolderrelativeSurName"
                              value={
                                authorisedaccessorAccountHolderrelativeSurName
                              }
                              onChange={handleChange}
                              className=""
                              placeholder={t("enter-your-surname-here")}
                            />
                          </div>
                          {selectAssociateref.current === "yes" &&
                            validationnErr &&
                            validationnErr.authorisedaccessorAccountHolderrelativeSurName && (
                              <p className="errorcss">
                                {
                                  validationnErr.authorisedaccessorAccountHolderrelativeSurName
                                }
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="input-groups icons">
                        <h6 className="input-label">
                          {t("if-yes-indicate-relationship")}
                        </h6>
                        <div className="input-field ref_input upload_icon kyc_country_dropdown">
                          <Select
                            options={relationOptions}
                            value={relationOptions.find(
                              (option) =>
                                option.value ===
                                authorisedaccessorAccountHolderrelativerelationship
                            )}
                            onChange={handleRelationChange}
                            placeholder={t("select-relationship")}
                            className=""
                            styles={colourStyles}
                          />
                        </div>
                        {validationnErr &&
                          validationnErr.authorisedaccessorAccountHolderrelativerelationship && (
                            <p className="errorcss">
                              {
                                validationnErr.authorisedaccessorAccountHolderrelativerelationship
                              }
                            </p>
                          )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="access_act_text">
                    <h3>{t("identity-document")}</h3>
                  </div>

                  <div className="driver_license_content">
                    <input
                      id="check-comp"
                      type="checkbox"
                      // className="checkbox-round"
                      className={
                        idproofref.current == ""
                          ? "checkbox-round"
                          : "checkbox-round-checked"
                      }
                      // onClick={handleLicense}
                    />
                    {/* <label htmlFor="check-license">  Driver's license</label>
                    <i class="ri-arrow-down-s-line"></i> */}
                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                      <Select
                        options={documentOptions}
                        onChange={handleDocumentChange}
                        placeholder={t("select-identity-document")}
                        value={documentOptions.find(
                          (option) =>
                            option.value === authorisedaccessoidentityDocument
                        )}
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>
                  {validationnErr &&
                    validationnErr.authorisedaccessoidentityDocument && (
                      <p className="errorcss">
                        {validationnErr.authorisedaccessoidentityDocument}
                      </p>
                    )}
                  {authorisedaccessoidentityDocument != "" && (
                    <>
                      <div className="driver_license_doc">
                        <div className="upload">
                          {idproofLoad == false ? (
                            valididproof == 0 ? (
                              <>
                                <div className="inner_frst_display">
                                  <i class="ri-upload-2-line"></i>
                                  <p>{t("click-to-upload-file")}</p>
                                </div>
                              </>
                            ) : valididproof == 1 ? (
                              <i class="bi bi-file-earmark-bar-graph"></i>
                            ) : (
                              <img
                                src={idproofref.current}
                                className="up_im_past"
                                alt="National Id Front"
                              />
                            )
                          ) : (
                            <div className="inner_frst_display">
                              <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                            </div>
                          )}

                          <input
                            type="file"
                            name="image"
                            className="image_upload_kyc"
                            onChange={(e) =>
                              imageUpload("identityDocument", e.target.files[0])
                            }
                          />
                          {idproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div className="">
                              <input
                                className="proofs_name"
                                disabled
                                value={idproofnameref.current}
                              ></input>
                            </div>
                          )}
                        </div>
                        {/* <span className="or_text">Or</span>
                        <div className="upload">
                          <img
                            src={require("../assets/webcam_icon.png")}
                            className="webcam_icon"
                          />
                          <p>Click to upload file</p>
                        </div> */}
                      </div>
                    </>
                  )}

                  <div className="driver_license_doc">
                    <div className="access_act_text">
                      <h3>{t("proof-of-address")}</h3>
                      <p className="clr-neutral">
                        {t("Should-have-the-same-address")}
                      </p>
                    </div>
                  </div>

                  <div className="driver_license_content">
                    <input
                      id="check-proof"
                      type="checkbox"
                      className={
                        addressProofref.current == ""
                          ? "checkbox-round"
                          : "checkbox-round-checked"
                      }
                      // onClick={handleCredit}
                    />
                    {/* <label htmlFor="custom-credit"> Credit Card Statement  <i class="ri-arrow-down-s-line"></i></label> */}
                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                      <Select
                        options={addressProofOptions}
                        onChange={handleAddressProofChange}
                        placeholder={t("select-address-proof")}
                        value={addressProofOptions.find(
                          (option) =>
                            option.value === authorisedaccessoaddressProof
                        )}
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>
                  {validationnErr &&
                    validationnErr.authorisedaccessoaddressProof && (
                      <p className="errorcss">
                        {validationnErr.authorisedaccessoaddressProof}
                      </p>
                    )}
                  {authorisedaccessoaddressProof != "" && (
                    <div className="driver_license_doc">
                      <div className="upload">
                        {addressProofLoad == false ? (
                          validaddressProof == 0 ? (
                            <>
                              <div className="inner_frst_display">
                                <i class="ri-upload-2-line"></i>
                                <p>{t("click-to-upload-file")}</p>
                              </div>
                            </>
                          ) : validaddressProof == 1 ? (
                            <i class="bi bi-file-earmark-bar-graph"></i>
                          ) : (
                            <img
                              src={addressProofref.current}
                              className="up_im_past"
                              alt="National Id Front"
                            />
                          )
                        ) : (
                          <div className="inner_frst_display">
                            <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                          </div>
                        )}

                        <input
                          type="file"
                          name="image"
                          className="image_upload_kyc"
                          onChange={(e) =>
                            imageUpload("addressProof", e.target.files[0])
                          }
                        />
                        {addressproofnameref.current == "" ? (
                          ""
                        ) : (
                          <div className="">
                            <input
                              className="proofs_name"
                              disabled
                              value={addressproofnameref.current}
                            ></input>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="Submit my-4" onClick={submit}>
                    {/* <Link to="/KycInformation6"> */}
                    <button>{t("next")}</button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
