import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ReferralTable = () => {
  const [referralHistory, setreferralHistory] = useState([]);
  const [referralcurrentpage, setreferralcurrentpage] = useState(1);
  const [referraltotalpage, setreferralTotalpages] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getReferralHistory(1);
  }, [0]);

  const getReferralHistory = async (page) => {
    try {
      var payload = {
        perpage: referralrecordpage,
        page: page,
      };
      var data = {
        apiUrl: apiService.getReferralHisotry,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setreferralHistory(resp.data.data);
        setreferralTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const referralrecordpage = 5;
  const loginpagerange = 5;

  const handlepagereferral = (event, pageNumber) => {
    setreferralcurrentpage(pageNumber);
    setreferralHistory(pageNumber);
  };

  return (
    <>
      <section>
        <Header />
      </section>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main h-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 pad-lft">
                <Side_bar />
              </div>

              <div className="col-lg-10 pad-lft">
                <section className="asset_section">
                  <div className="row">
                    <div className="buy_head">
                      <div className="Buycrypto_title">{t("history")}</div>
                      <ul className="history-lists">
                        <Link to="/depositHistory" className="history-links ">
                          {t("deposit")}
                        </Link>
                        <Link to="/withdrawHistory" className="history-links ">
                          {t("withdraw")}
                        </Link>
                        <Link to="/tradeHistory" className="history-links ">
                          {t("trade")}
                        </Link>
                        <Link to="/loginHistory" className="history-links ">
                          {t("login")}
                        </Link>
                        <Link
                          to="/referralHistory"
                          className="history-links active"
                        >
                          {t("referral")}
                        </Link>
                        <Link to="/adminrewardTable" className="history-links">
                          {t("reward")}
                        </Link>
                        <Link to="/stakingHistory" className="history-links">
                          {t("staking")}
                        </Link>
                        <Link to="/swapHistory" className="history-links">
                          {t("swap")}
                        </Link>
                        <Link to="/openorderHistory" className="history-links ">
                          {t("open-order-history")}
                        </Link>
                        <Link
                          to="/cancelorderHistory"
                          className="history-links "
                        >
                          {t("cancel-order-history")}
                        </Link>
                        <Link to="/stoporderHistory" className="history-links ">
                          {t("stop-order-history")}
                        </Link>
                      </ul>
                      {/* <AssetListTable /> */}

                      <div className="table-responsive table-cont">
                        <table className="table">
                          <thead className="ref_table_head">
                            <tr className="stake-head font-satoshi thead-bor-bottom">
                              <th>S.No</th>
                              <th className="opt-nowrap txt-center pad-left-23">
                                {t("username")}
                              </th>
                              <th className="opt-btn-flex table-action ">
                                {t("date-time")}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {referralHistory.length > 0 && referralHistory ? (
                              referralHistory.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="opt-percent font-satoshi font_14 px-4 pad-top-14px">
                                      {i + 1}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                      {item.username}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 px-2 text-center pad-top-14px">
                                      {moment(item.createdDate).format("lll")}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={3} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.png")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      {t("no-records-found")}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {referralHistory && referralHistory.length > 0 ? (
                          <div className="pagination">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(
                                  referraltotalpage / referralrecordpage
                                )}
                                page={referralcurrentpage}
                                onChange={handlepagereferral}
                                size="small"
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                    sx={{
                                      "&.Mui-selected": {
                                        backgroundColor: "#33c2b9",
                                        color: "#fff",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default ReferralTable;
