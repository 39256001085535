import React, { useState, useEffect } from "react";
import Header from "./Header";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { Link } from "react-router-dom";

import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

const Kyb = () => {
  useEffect(() => {
    get_kyc_datails();
  }, []);

  const [kycStatus, setKycStatus] = useState("");
  const [rejectReason, setrejectReason] = useState("");

  const get_kyc_datails = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      console.log(resp.data.kycStatus, "------");
      setKycStatus(resp.data === null ? "" : resp.data.kycStatus);
      setrejectReason(resp.data === null ? "" : resp.data.rejectReason);
    } catch (err) {}
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="Kyb_main assets_main ">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 pad-lft">
              <Side_bar />
            </div>

            <div className="col-lg-10">
              <section className="asset_section">
                <div className="row">
                  <div className="identification_title">Identification</div>
                 
                  <div className="col-lg-7">
                  {kycStatus == 3 ? (

                  <div className="standard_verify_box">
                      <div className="standard_verify_content">
                      <h3>Reject Reason :</h3>
                      <h5 className="text-danger">
                        {rejectReason ? rejectReason:"Not Valid"}{" "}
                        </h5>
                        </div>
                        </div>
                  ):""}
                    <div className="standard_verify_box">
                      <div className="standard_verify_content">
                        <h3>Standard Identity Verification</h3>
                        <p className="font-satoshi">
                          It only takes 3-5 minutes to verify your account.
                        </p>
                        {[0, 2].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white" to="/kypProcess">Verify Now</Link>
                          </button>
                        ) : [4].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white">Pending</Link>
                          </button>
                        ) : [1].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white">Verified</Link>
                          </button>
                        ) : [3].includes(kycStatus) ? (
                            <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white" to="/kypProcess">Verify Now</Link>
                          </button>
                        ):
                        (
                          <button
                          className="verify_btn opt-nowrap"
                          type="button"
                        >
                          <Link className="text-white" to="/kypProcess">Verify Now</Link>
                        </button>
                        )}
                      </div>
                      <div className="standard_verify_img">
                        <img
                          src={require("../assets/standard_verify_img.png")}
                        />
                      </div>
                    </div>
                    <div className="individual_kyc_box">
                      <div className="individual_title">Corporates KYB</div>
                      <div className="identification_features">
                        <div className="features">
                          <h3 className="font-satoshi">Features</h3>
                          <p>Withdrawals</p>
                          <p>Trading</p>
                          <p>Deposits/Crypto Purchases</p>
                          <p>Others</p>
                        </div>
                        <div className="features unverified">
                          <h3 className="font-satoshi">Unverified</h3>
                          <p>0 USDT</p>
                          <p>-</p>
                          <p>-</p>
                          <p>-</p>
                        </div>
                        <div className="features unverified">
                          <h3 className="font-satoshi">Verified</h3>
                          <p className="text_green">999,999 USDT</p>
                          <p className="text_green">
                            <i class="ri-check-line"></i>
                          </p>
                          <p className="text_green">
                            <i class="ri-check-line"></i>
                          </p>
                          <p className="text_green">More Event Bonus</p>
                        </div>
                      </div>
                    </div>
                    <div className="standard_verify_box">
                      <div className="standard_verify_content">
                        <h3>Requirements</h3>
                        <p className="font-satoshi">
                          <img src={require("../assets/Id_doc_icon.png")} /> ID
                          Document/Driving License/Passport
                        </p>
                        <p className="font-satoshi">
                          <img src={require("../assets/facial_icon.png")} />
                          Facial Recognition
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    {/* FAQ */}
                    <div>
                      <div className="container">
                        <div
                          class="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="faq mt-5">
                            <h5 className="faq-title">FAQ</h5>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  1. How do I deposit crypto into my FIDEX
                                  account?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the first item's accordion body.
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingTwo"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  2. How does crypto deposit work?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the second item's accordion body. Let's
                                  imagine this being filled with some actual
                                  content.
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  3. What is the minimum deposit in
                                  cryptocurrency?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the third item's accordion body. Nothing more
                                  exciting happening here in terms of content,
                                  but just filling up the space to make it look,
                                  at least at first glance, a bit more
                                  representative of how this would look in a
                                  real-world application.
                                </div>
                              </div>
                            </div>
                            {/* four */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFour"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFour"
                                >
                                  4.How does crypto transfer to real money?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the third item's accordion body. Nothing more
                                  exciting happening here in terms of content,
                                  but just filling up the space to make it look,
                                  at least at first glance, a bit more
                                  representative of how this would look in a
                                  real-world application.
                                </div>
                              </div>
                            </div>
                            {/* five */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFive"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFive"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFive"
                                >
                                  5. How do I deposit and withdraw from crypto?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the third item's accordion body. Nothing more
                                  exciting happening here in terms of content,
                                  but just filling up the space to make it look,
                                  at least at first glance, a bit more
                                  representative of how this would look in a
                                  real-world application.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Kyb;
