import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>

        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-container">
                  <h5 className="mb-5">{t("privacy-policy")}</h5>
                  <h6>{t("effective-date")} : 31.8.2024</h6>
                  <div>
                    <ol>
                      {/* Introduction */}
                      <li className="policy-sub-heading">
                        {t("introduction")}
                      </li>
                      <p>{t("welcome-to-fidex-we-privacy-policy")}</p>{" "}
                      {/*  Information We Collect */}
                      <li className="policy-sub-heading">
                        {" "}
                        {t("information-we-collect")}
                      </li>
                      <p>{t("we-may-collect-and-process")}:</p>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {t("contact-information")}:{" "}
                          </span>
                          {t("name-email-address-ph-no")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("account-information")}:{" "}
                          </span>
                          {t("username-password-and-other")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("financial-information")}:{" "}
                          </span>
                          {t("payment-details-transaction-history")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("usage-data")}:{" "}
                          </span>
                          {t("information-about-how-you-use")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("technical-data")}:{" "}
                          </span>
                          {t("device-information-log-files")}
                        </li>
                      </ul>{" "}
                      {/* How We Use Your Information */}
                      <li className="policy-sub-heading">
                        {t("how-we-use-your-information")}
                      </li>
                      <p>{t("we-use-your-personal-information")}:</p>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("to-provide-and-manage-services")}:{" "}
                          </span>
                          {t("to-process-transactions")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("to-improve-our-platform")}:{" "}
                          </span>
                          {t("to-analyze-usage-patterns")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("to-communicate-with-you")}:{" "}
                          </span>
                          {t("to-send-you-updates-newsletters")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("to-comply-with-legal-obligations")}:{" "}
                          </span>
                          {t("to-adhere-to-regulatory")}
                        </li>
                      </ul>
                      {/* How We Share Your Information*/}
                      <li className="policy-sub-heading">
                        {t("how-we-share-your-information")}
                      </li>
                      <p>{t("we-may-share-your-personal-information")}:</p>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("service-providers")}:{" "}
                          </span>
                          {t("third-party-vendors-who-perform")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("regulatory-authorities")}:{" "}
                          </span>
                          {t("to-comply-with-legal-requirements")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("business-transfers")}:{" "}
                          </span>
                          {t("in-connection-with-a-merger")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("affiliates")}:{" "}
                          </span>
                          {t("companies-within-our-corporation")}
                        </li>
                      </ul>
                      {/*Data Security*/}
                      <li className="policy-sub-heading">
                        {t("data-security")}
                      </li>
                      <p>{t("we-implement-reasonable-technical-and")}</p>
                      {/* Your Rights*/}
                      <li className="policy-sub-heading">{t("your-rights")}</li>
                      <p>{t("depending-on-your-jurisdiction")}:</p>{" "}
                      <ul>
                        <li>
                          <span className="policy-bold">{t("access")}: </span>
                          {t("to-request-a-copy-of-your-personal")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("correction")}:{" "}
                          </span>
                          {t("to-request-correction-of-inaccruate")}
                        </li>
                        <li>
                          <span className="policy-bold">{t("deletion")}: </span>
                          {t("to-request-the-deletion-of-your-personal")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("objection")}:{" "}
                          </span>
                          {t("to-object-to-or-restrict-the-processing")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("data-portability")}:{" "}
                          </span>
                          {t("to-request-a-copy-of-your")}
                        </li>
                      </ul>
                      <p>
                        {" "}
                        {t("to-exercise-these-rights")}
                        {/* [voltrixcrypt@gmail.com]. */} []
                      </p>
                      {/*  Cookies and Tracking Technologies*/}
                      <li className="policy-sub-heading">
                        {" "}
                        {t("cookies-and-tracking-technologies")}
                      </li>
                      <p>{t("we-use-cookies-and-similar-tracking")}</p>
                      {/*  Children's Privacy*/}
                      <li className="policy-sub-heading">
                        {" "}
                        {t("childrens-privacy")}
                      </li>
                      <p>{t("our-platform-is-not-intended")}</p>
                      {/*  Changes to This Privacy Policy*/}
                      <li className="policy-sub-heading">
                        {" "}
                        {t("changes-to-this-privacy-policy")}
                      </li>
                      <p>{t("we-may-update-this-privacy-policy")}</p>{" "}
                      {/*   Contact Information*/}
                      {/* <li className="policy-sub-heading">
                        {" "}
                        Contact Information
                      </li>{" "}
                      <p>
                        If you have any questions or concerns about this Privacy
                        Policy or our data practices, please contact us at :
                      </p>
                      <div>
                        <span className="policy-sub-heading">Email: </span>{" "}
                        <span className="terms-email">
                          voltrixcrypt@gmail.com
                        </span>
                      </div>
                      <div className="terms-address">
                        <span className="policy-sub-heading">Address: </span>{" "}
                        <p className="policy-terms-ad">
                          VOLTCRYPT NETWORKING PRIVATE LIMITED. <br /> 45 - A,
                          Main Road, Arunachalapuram, <br /> Vickramasingapuram,{" "}
                          <br />
                          Ambasamudram, Tirunelveli- 627425, <br /> Tamil Nadu –
                          India.
                        </p>
                      </div> */}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Privacy;
