import React, { useState, useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { Link } from "react-router-dom";

import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  useEffect(() => {
    get_kyc_datails();
  }, []);

  const [kycStatus, setKycStatus] = useState("");
  const [rejectReason, setrejectReason] = useState("");

  const get_kyc_datails = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      console.log(resp.data.kycStatus, "------");
      setKycStatus(resp.data === null ? "" : resp.data.kycStatus);
      setrejectReason(resp.data === null ? "" : resp.data.rejectReason);
    } catch (err) {}
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main ">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 pad-lft">
              <Side_bar />
            </div>

            <div className="col-lg-10">
              <section className="asset_section">
                <div className="row">
                  <div className="identification_title">
                    {t("identification")}
                  </div>

                  <div className="col-lg-7">
                    {kycStatus == 3 ? (
                      <div className="standard_verify_box">
                        <div className="standard_verify_content">
                          <h3>{t("reject-reason")} :</h3>
                          <h5 className="text-danger">
                            {rejectReason ? rejectReason : "Not Valid"}{" "}
                          </h5>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="standard_verify_box">
                      <div className="standard_verify_content">
                        <h3>{t("standard-id-verify")}</h3>
                        <p className="font-satoshi">
                          {t("standard-id-verify-content")}
                        </p>
                        {[0, 2].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white" to="/kycprocess">
                              {t("verify-now")}
                            </Link>
                          </button>
                        ) : [4].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white">{t("pending")}</Link>
                          </button>
                        ) : [1].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white">{t("verified")}</Link>
                          </button>
                        ) : [3].includes(kycStatus) ? (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white" to="/kycprocess">
                              {t("verify-now")}
                            </Link>
                          </button>
                        ) : (
                          <button
                            className="verify_btn opt-nowrap"
                            type="button"
                          >
                            <Link className="text-white" to="/kycprocess">
                              {t("verify-now")}
                            </Link>
                          </button>
                        )}
                      </div>

                      <div className="standard_verify_img">
                        <img
                          src={require("../assets/standard_verify_img.webp")}
                        />
                      </div>
                    </div>

                    <div className="individual_kyc_box">
                      <div className="individual_title">
                        {t("individual-kyc")}
                      </div>
                      <div className="identification_features">
                        <div className="features">
                          <h3 className="font-satoshi">{t("features")}</h3>
                          <p>{t("withdraw")}</p>
                          <p>{t("trading")}</p>
                          <p>
                            {t("deposits")}/{t("crypto-purchases")}
                          </p>
                          <p>{t("others")}</p>
                        </div>
                        <div className="features unverified">
                          <h3 className="font-satoshi">{t("un-verified")}</h3>
                          <p>0 USDT</p>
                          <p>-</p>
                          <p>-</p>
                          <p>-</p>
                        </div>
                        <div className="features unverified">
                          <h3 className="font-satoshi">{t("verified")}</h3>
                          <p className="text_green">999,999 USDT</p>
                          <p className="text_green">
                            <i class="ri-check-line"></i>
                          </p>
                          <p className="text_green">
                            <i class="ri-check-line"></i>
                          </p>
                          <p className="text_green">{t("more-event-bonus")}</p>
                        </div>
                      </div>
                    </div>

                    <div className="standard_verify_box">
                      <div className="standard_verify_content">
                        <h3>{t("requirements")}</h3>
                        <p className="font-satoshi">
                          <img src={require("../assets/Id_doc_icon.png")} />{" "}
                          {t("id-doc")}/{t("driving-license")}/{t("passport")}
                        </p>
                        <p className="font-satoshi">
                          <img src={require("../assets/facial_icon.png")} />
                          {t("face-recog")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    {/* FAQ */}
                    <div>
                      <div className="container">
                        <div
                          class="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="faq mt-5">
                            <h5 className="faq-title">FAQ</h5>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  1. How do I deposit crypto into my FIDEX
                                  account?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the first item's accordion body.
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingTwo"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  2. How does crypto deposit work?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the second item's accordion body. Let's
                                  imagine this being filled with some actual
                                  content.
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  3. What is the minimum deposit in
                                  cryptocurrency?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the third item's accordion body. Nothing more
                                  exciting happening here in terms of content,
                                  but just filling up the space to make it look,
                                  at least at first glance, a bit more
                                  representative of how this would look in a
                                  real-world application.
                                </div>
                              </div>
                            </div>
                            {/* four */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFour"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFour"
                                >
                                  4.How does crypto transfer to real money?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the third item's accordion body. Nothing more
                                  exciting happening here in terms of content,
                                  but just filling up the space to make it look,
                                  at least at first glance, a bit more
                                  representative of how this would look in a
                                  real-world application.
                                </div>
                              </div>
                            </div>
                            {/* five */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFive"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFive"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFive"
                                >
                                  5. How do I deposit and withdraw from crypto?
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  Placeholder content for this accordion, which
                                  is intended to demonstrate the{" "}
                                  <code>.accordion-flush</code> class. This is
                                  the third item's accordion body. Nothing more
                                  exciting happening here in terms of content,
                                  but just filling up the space to make it look,
                                  at least at first glance, a bit more
                                  representative of how this would look in a
                                  real-world application.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-md">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Identity verification
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="country-content">
                        <h4>Country/Region of Residence</h4>
                        <PhoneInput
                          country={"us"}
                          //   value={mobile}
                          onChange={handlePhoneChange}
                          inputStyle={{ width: "100%" }}
                        />
                      </div>
                      <h4 className="select_id_text">Select ID Type</h4>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/passport_icon.png")} />
                          <p>Passport</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/passport_icon.png")} />
                          <p>Passport</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/passport_icon.png")} />
                          <p>Passport</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button className="modal_continue_btn">Continue</button>
                      <p className="modal_footer_text">
                        This information is used for identity verification only,
                        and
                        <br /> will be kept secure by FIDEX
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* -- Modal 2--
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-md">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Personal Verification
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body personal_verify_body">
                      <div className="first_name_content">
                        <div className="first_name">
                          <h4>First Name</h4>
                          <input
                            type="text"
                            placeholder="Enter the First Name"
                          />
                        </div>
                        <div className="first_name">
                          <h4>Last Name</h4>
                          <input
                            type="text"
                            placeholder="Enter the Last Name"
                          />
                        </div>
                      </div>
                      <div className="id_number">
                        <div className="first_name">
                          <h4>ID Number</h4>
                          <input
                            type="text"
                            placeholder="Enter the First Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button className="modal_continue_btn">Continue</button>
                      <button className="modal_back_btn">Back</button>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* -- Modal 3-- 
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-md">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        ID verification & Face Verification
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <h4 className="select_id_text">
                        Choose an upload method
                      </h4>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/mobile_popup_icon.png")} />
                          <p>Continue on mobile</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/take_photo_icon.png")} />
                          <p>Take photo using webcame</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <div className="seleted_id_type">
                        <div className="passport">
                          <img src={require("../assets/upload_file_icon.png")} />
                          <p>Upload file from this device</p>
                        </div>
                        <div className="check_circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button className="modal_continue_btn">Continue</button>
                      <p className="modal_footer_text">
                        Your personal information is encrypted.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
