import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer_content_section">
      <div className="container bor-bottom">
        <div className="row">
          <div className="col-lg-6">
            <div className="footer_content">
              <img
                src={require("../assets/footer_logo.webp")}
                className="footer_light_logo"
              />
              <img
                src={require("../assets/fidex_dark_logo.webp")}
                className="footer_logo_dark"
              />
              <p>{t("footer-content")}</p>
            </div>
          </div>

          <div className="col-lg-6 services_content">
            <div className="services">
              <h3>{t("services")}</h3>
              <li>
                <a href="/staking">{t("staking")}</a>
              </li>
              <li>
                <a href="/deposit">{t("deposit")}</a>
              </li>
              <li>
                <a href="/Withdraw">{t("withdraw")}</a>
              </li>
              <li>
                <a href="/buycrypto">{t("buycrpto")}</a>
              </li>
              <li>
                <a href="/trade/ETH_USDC">{t("trade")}</a>
              </li>
              <li>
                <a href="/swap">{t("convert")}</a>
              </li>
            </div>
            <div className="services">
              <h3>{t("company")}</h3>
              <li>
                <a href="">{t("about-us")}</a>
              </li>
              <li>
                <a href="">{t("contact-us")}</a>
              </li>
              <li>
                <a href="">{t("work-at-fidex")}</a>
              </li>
            </div>
            <div className="services">
              <h3>{t("support")}</h3>
              <li>
                <a href="/support">{t("support")}</a>
              </li>
              <li>
                <a href="/privacy" target="_blank">
                  {t("privacy-policy")}
                </a>
              </li>
              <li>
                <a href="">{t("cookies-policy")}</a>
              </li>
              <li>
                <a href="/terms" target="_blank">
                  {t("terms")}
                </a>
              </li>
              <li>
                <a href="">{t("complaint-channel")}</a>
              </li>
            </div>
            <div className="services">
              <h3>{t("socials")}</h3>
              <Link>
                <img src={require("../assets/twitter x.webp")} />
              </Link>
              <Link>
                <img src={require("../assets/Facebook.webp")} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">&copy; {t("copyrights")}</div>
    </footer>
  );
};

export default Footer;
